.select {
  $this: & !default;
  position: relative;
  z-index: 2;
  min-height: 38px;
  border: 1px solid $input-default-border-color;
  border-radius: $border-radius;
  background-color: $input-default-bg-color;
  box-shadow: 0 3px 7px rgba(136, 139, 141, 0.20) inset;
  color: $input-default-color;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  transition: box-shadow 0.250s ease, color 0.250s ease;
  outline: none;
  text-align: left;

  &--addon-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &--addon-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  .ng-invalid.ng-touched > &,
  &--error {
    position: relative;
    z-index: 2;
    border-color: $error-color;
    box-shadow: 0 3px 7px rgba(136, 139, 141, 0.20) inset;
  }

  &--open {
    border: 2px solid var(--primary-color) !important;
    border-bottom: none !important;
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: 0 4px 16px rgba(69, 69, 69, 0.15);
    color: var(--primary-color);

    #{$this}__single {
      padding-right: 7px;
    }

    #{$this}__value,
    #{$this}__placeholder {
      padding: {
        top: $input-padding-y - 2;
        left: $input-padding-x - 1;
        bottom: $input-padding-y;
      }
    }

    #{$this}__multiple {
      padding: 7px 25px 3px 10px;
    }

    &#{$this}--show-above {
      border: 2px solid var(--primary-color) !important;
      border-top: none !important;
      border-radius: 0 0 $border-radius $border-radius;
      // order: 2;

      #{$this}__value,
      #{$this}__placeholder {
        padding: #{$input-padding-y} #{$input-padding-x - 1} #{$input-padding-y - 2};
      }

      #{$this}__multiple {
        padding: 9px 25px 1px 10px;
      }
    }
  }

  &--disabled,
  &--readonly {
    background: #f6f6f6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALElEQVQYV2NkwALevn37nxFdHCQoLCzMiCIBEwQphksgC8Il0AXBEtgEQRIA6XcXzRBgFUoAAAAASUVORK5CYII=) repeat;
    color: $input-disabled-color;
    pointer-events: none;

    #{$this}__value,
    #{$this}__placeholder {
      color: $gray-color;
    }
  }

  &--fluid {
    width: 100%;
  }

  &__single,
  &__multiple {
    display: flex;
    min-height: 38px;
    padding-right: 8px;
    cursor: pointer;
    user-select: none;
  }

  &__value,
  &__placeholder {
    flex: 1;
    overflow: hidden;
    padding: #{$input-padding-y - 1} $input-padding-x;
    color: $input-default-color;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: $input-line-height;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__placeholder--multiple {
    position: absolute;
    top: 1px;
    left: 1px;
  }

  &__clear-button,
  &__toggle-button {
    position: relative;
    width: 30px;
    color: #aaa;
    line-height: $input-line-height;
    padding: 12px 0;
    text-align: center;

    &:before {
      @include font-setup();
      position: absolute;
      top: 50%;
      left: 50%;
      content: $icon-chevron;
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
      transition: transform 0.2s ease-out;
    }

    &-text {
      display: none;
    }
  }

  &--addon {
    #{$this}__clear-button,
    #{$this}__toggle-button {
      width: 20px;
    }
  }

  &__clear-button {
    width: 24px;
    color: var(--primary-color);

    &:before {
      content: $icon-cross;
      transform-origin: 50% 53%;
    }

    &:hover:before {
      transform: translate3d(-50%, -50%, 0) rotate(-180deg);
    }
  }

  &__toggle-button {
    color: $accent-color;

    &--open:before {
      transform: translate3d(-50%, -50%, 0) rotate(-180deg);
    }
  }

  &__multiple {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 8px 40px 2px 11px;
    width: 100%;

    #{$this}__toggle-button {
      position: absolute;
      top: 0;
      right: 5px;
      bottom: 0;
    }
  }

  &__tag {
    flex-shrink: 1;
    position: relative;
    display: inline-block;
    margin: 0 5px 6px 0;
    padding: 0 25px 0 7px;
    border-radius: $border-radius;
    background-color: var(--secondary-color);
    color: #fff;
    font-size: var(--base-font-size);
    box-shadow: 0 1px 0 var(--select-tag-box-shadow-color);
    cursor: default;
    line-height: 24px;
  }

  &__tag-close {
    position: absolute;
    top: 2px;
    right: 0;
    display: block;
    width: 20px;
    cursor: pointer;
  }

  &__multiple-input {
    overflow: hidden;
    height: 25px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    color: $input-default-color;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    white-space: nowrap;
    box-shadow: none;
    background: none;

    &:focus {
      box-shadow: none;
    }
  }

  &--searching &__multiple-input {
    min-width: 350px;
  }

  &__dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 0 10px #fff;
    background: #fff;
    border: 2px solid var(--primary-color);
    border-top-width: 0;
    border-radius: 0 0 $border-radius $border-radius;
    text-align: left;

    &--autocomplete {
      height: auto;

      #{$this}__dropdown-item {
        &:hover {
          background: $primary-color;
          color: #fff;
        }
      }
    }
  }

  &__dropdown--show-above {
    top: auto;
    bottom: 0;
    border-top-width: 2px;
    border-bottom-width: 0;
    border-radius: $border-radius $border-radius 0 0;
    border-color: var(--primary-color);

    #{$this}__dropdown-filter {
      padding-top: 10px;
    }
  }

  &__dropdown-filter {
    width: 100%;
    padding: 0 10px 10px;

    .input {
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 2px var(--primary-color) inset;
      }
    }
  }

  &__dropdown-options {
    position: relative;
    overflow-y: auto;
    max-height: 200px;
  }

  &__dropdown-container {
    &--scrollable {
      box-shadow: inset 0px -20px 20px -20px rgba(224, 224, 224, 0.9);
    }
  }

  &__dropdown-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__dropdown-item,
  &__dropdown-message {
    min-height: 40px;
    padding: 12px 12px;
    cursor: pointer;
    user-select: none;

    &--selected {
      background: var(--select-dropdown-selected-color);
    }

    &--highlighted {
      background: var(--primary-color);
      color: #fff;
    }

    &--has-children {
      font-weight: 600;
      cursor: default;
    }

    &--is-child {
      padding-left: 20px;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &--unselectable {
      color: $input-default-color !important;

      &:hover {
        background: #fff !important;
        color: $input-default-color !important;
        cursor: default !important;
      }
    }
  }
}
