.data-list {
  $this: & !default;
  position: relative;

  &__flex {
    display: flex;
  }

  &__title {
    display: block;
    flex: 1 1 auto;
  }

  &__message {
    background-color: $light-gray-color;
    text-align: center;
    padding: 15px;
    font-size: var(--font-size-lg);
  }

  &__text {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      font-weight: var(--font-weight-bold);
    }
  }

  &__status-container {
    padding: 0 20px;
    display: flex;
    width: 100%;
    max-width: 200px;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &--wide {
      max-width: 300px;
    }
  }

  &__item {
    position: relative;
    cursor: pointer;
    display: flex;

    &:first-of-type {
      box-shadow: 0 -1px 1px -1px $dark-gray-color inset,
      0 1px 1px -1px $dark-gray-color inset;
    }

    box-shadow: 0 -1px 1px -1px $dark-gray-color inset;

    &:nth-child(even) {
      background: lighten($light-gray-color, 2%);
    }

    &:hover {
      background: $light-gray-color;
      box-shadow: 0 0 0 1px $input-focus-border-color inset;
    }
  }

  &__heading {
    margin-bottom: 10px;
    font-weight: var(--font-weight-heavy);
    font-size: 20px;

    &--small {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;
    }

    &--primary {
      color: $primary-color;
    }
  }

  &__content-container {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__content {
    width: 100%;
    padding: $input-padding;
  }

  &__creator {
    color: $primary-color;
  }

  &__checkbox-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .checkbox__text:before,
    .checkbox__label:before,
    .checkbox__label:after {
      top: -6px;
    }
  }

  &__label {
    display: block;
    margin-right: 10px;
  }

  &__header-container {
    display: flex;
    padding-bottom: 20px;
    justify-content: flex-end;
  }

  &__icon {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: -11px;
    background: #fff;
    border-radius: 50%;
    color: $info-color;
    font-size: 24px;

    &--block {
      position: static;
      top: auto;
      left: auto;
      margin: 5px;
      background: none;
      border-radius: none;

      &.icon-error {
        color: $error-color;
      }
    }
  }

  &__overlay {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .8);
  }
}
