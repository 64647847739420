.pagination {
  display: flex;
  align-items: center;

  &__offset {
    user-select: none;
    text-align: left;
  }

  &__input {
    width: 60px;
  }

  &__limit {
    text-align: right;
  }

  &__select {
    width: 100px;
  }

  &__flex {
    flex: 1;
  }

  &__loader {
    position: relative;
    opacity: 0;
    z-index: 5;
    color: transparent !important;
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.85);
    cursor: default;
    pointer-events: none;
    user-select: none;
    will-change: opacity;
    transition: opacity 0.3s ease;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      margin: -50% 0 0 -50%;
      border-radius: 100%;
      border-top: 2px solid rgba(160, 160, 160, 0.15);
      border-right: 2px solid rgba(160, 160, 160, 0.15);
      border-bottom: 2px solid rgba(160, 160, 160, 0.15);
      border-left: 2px solid #6b6b6b;
      animation: loader .6s linear;
      animation-iteration-count: infinite;
    }

    &--show {
      opacity: 1;
    }
  }
}
