.page-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 46px 0;
  &__flex {
    flex: 1;
  }

  &__buttons {
    flex-shrink: 0;
    padding-left: 20px;
  }

  &__description {
    padding: 5px 16px;
  }

  &__title {
    margin: 0;
    max-height: 135px;
    overflow: hidden;
  }
}
