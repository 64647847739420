.blockquote {
  position: relative;
  padding-left: 70px;
  font-family: $serif-font-family;
  font-weight: 400;
  font-style: italic;
  line-height: $base-line-height;

  &:before {
    content: '\201D'; // Right double quotation mark
    position: absolute;
    top: -4px;
    left: 0;
    color: var(--blockquote-color);
    font-size: 144px;
    line-height: 1;
  }
}
