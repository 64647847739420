.datepicker {
  $this: & !default;
  position: relative;
  display: inline-block;
  min-width: 30px;
  font-family: $base-font-family;

  * {
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  &__input {
    padding-right: 40px;
    box-shadow: 0 0 0 1px #ccc inset, 0 3px 7px rgba(136, 139, 141, 0.2) inset;
    color: $base-color;
    cursor: pointer;

    &--invalid {
      color: $accent-color !important;
    }

    &.input--error {
      position: relative;
      z-index: 2;
      box-shadow: 0 0 0 1px $accent-color inset,
      0 3px 7px rgba(136, 139, 141, 0.20) inset;
    }

    &--with-clear {
      padding-right: 62px;
    }
  }

  &__input-wrapper {
    position: relative;
  }

  &__selector {
    position: absolute;
    top: 100%;
    width: 252px;
    margin-top: 8px;
    margin-left: -1px;
    padding: 0;
    border: 2px solid var(--primary-color);
    border-radius: $border-radius;
    box-shadow: 0 4px 16px rgba(69, 69, 69, 0.15);
    z-index: 100;
    animation: selectorfadein 0.1s;

    &:focus {
      border: 2px solid var(--primary-color);
      outline: none;
    }

    &--inline {
      position: relative;
      margin-top: -1px;
    }

    &--align-right {
      right: -1px;
    }

    &--static {
      position: static;
    }

    &--arrow {
      margin-top: 12px;
      padding: 0;
      background: #fafafa;

      &:after,
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
        border: solid transparent;
      }

      &:after {
        margin-left: -8px;
        border-color: rgba(250, 250, 250, 0);
        border-bottom-color: #fafafa;
        border-width: 8px;
      }

      &:before {
        margin-left: -11px;
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: var(--primary-color);
        border-width: 11px;
      }
    }

    &--arrow-left:before,
    &--arrow-left:after {
      left: 24px;
    }

    &--arrow-right:before,
    &--arrow-right:after {
      left: 224px;
    }
  }

  &__clear-btn,
  &__calendar-btn {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 40px;
    cursor: pointer;

    &:before {
      @include font-setup();
      position: absolute;
      top: 13px;
      transform: translate3d(-50%, 0, 0) rotate(0deg);
      transition: transform 0.2s ease-out;
    }

    &--disabled {
      opacity: 0.5;
      transition: none;
      cursor: not-allowed;
    }
  }

  &__clear-btn {
    right: 40px;;
    width: 24px;
    color: $primary-color;

    &:before {
      content: $icon-cross;
      left: 12px;
      transform-origin: 7.5px 8.5px;
    }

    &:not(#{$this}__clear-btn--disabled):hover:before {
      transform: translate3d(-50%, 0, 0) rotate(-180deg);
    }
  }

  &__calendar-btn {
    right: 0;
    margin-right: 8px;
    width: 30px;
    color: $accent-color;

    &:before {
      content: $icon-calendar;
      left: 15px;
    }
  }

  &__header {
    display: table;
    border-spacing: 0;
    width: 100%;
    height: 30px;
    border: none;
    background-color: #fafafa;
  }

  &__header-row {
    border: none;
  }

  &__header-cell {
    vertical-align: middle;
    padding: 0;
    border: none;
    line-height: 0;

    &:nth-child(1) {
      padding-left: 4px;
    }

    &:nth-child(2) {
      padding: 8px 0;
      text-align: center;
    }

    &:nth-child(3) {
      padding-right: 4px;
    }
  }

  &__header-btn-cell {
    display: table-cell;
    vertical-align: middle;
    background-color: #fafafa;
  }

  &__header-btn {
    position: relative;
    width: 16px;
    height: 22px;
    border: none;
    background: transparent;
    color: var(--datepicker-header-button-color);

    &--prev:before,
    &--next:before {
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 600;
      font-size: var(--font-size-xxs);
    }

    &--prev:before {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }

    &--next:before {
      transform: translate3d(-50%, -50%, 0) rotate(270deg);
    }

    &--enabled {
      cursor: pointer;
    }

    &--disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__header-month-txt,
  &__header-year-txt {
    overflow: hidden;
    display: table-cell;
    width: 40px;
    max-width: 40px;
    height: 26px;
    color: #000;
    text-align: center;
    vertical-align: middle;
    font-size: var(--font-size-sm);
    white-space: nowrap;
  }

  &__header-month-input,
  &__header-year-input {
    width: 40px;
    height: 22px;
    border: 1px solid #ccc;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    outline: none;

    &--invalid {
      background-color: #f1dede;
    }
  }

  &__header-label-btn {
    height: 22px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: #fafafa;
    color: #454545;
    font-size: var(--font-size-xs);

    &--month-label,
    &--year-label {
      cursor: pointer;
    }
  }

  &__header-today-btn {
    overflow: hidden;
    min-width: 60px;
    max-width: 72px;
    height: 24px;
    padding: 0 4px;
    border: none;
    border-radius: 2px;
    background-color: var(--datepicker-header-today-color);
    color: #454545;
    font-size: var(--font-size-xs);
    white-space: nowrap;

    &:focus {
      background-color: #d7cae9;
    }

    &--enabled {
      cursor: pointer;
    }

    &--enabled:hover {
      background-color: var(--datepicker-header-today-hover-color);
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__cal {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    color: #003366;
    font-size: var(--font-size-sm);
    line-height: 1.1;
  }

  &__cal-head-row {
    border: none;
  }

  &__cal-head-cell {
    overflow: hidden;
    vertical-align: middle;
    max-width: 36px;
    padding: 8px 5px;
    border-collapse: collapse;
    border: none;
    background-color: var(--datepicker-car-head-cell-color);
    color: #454545;
    text-align: center;
    font-size: var(--font-size-xs);
    font-weight: 600;
    white-space: nowrap;

    &--br {
      width: 20px;
      border-right: 1px solid #bbb;
    }
  }

  &__cal-body-row {
    border: none;
  }

  &__cal-body-cell {
    vertical-align: middle;
    height: 30px;
    padding: 5px;
    border: none;
    border-collapse: collapse;
    background-color: #f7f5fb;
    color: #454545;
    text-align: center;
    font-size: var(--font-size-xs);
    font-weight: 600;
    cursor: pointer;

    &--br {
      border-right: 1px solid #ccc;
      color: #000;
      font-size: var(--font-size-xxs);
      cursor: default;
    }

    &--curr-month {
      background-color: #fff;
    }

    &--curr-month:hover {
      background-color: var(--datepicker-car-body-cell-curr-month-hover-color);
    }

    &--selected-day {
      background-color: var(--datepicker-car-body-cell-selected-day-color);
    }

    &--disabled {
      background: #fbefef !important;
      color: #ccc !important;
      cursor: default !important;
    }
  }

  &__cal-body-div {
    vertical-align: middle;
    background-color: inherit;

    &--prev-month,
    &--next-month {
      color: #b9bbbb;
    }

    &--sunday {
      color: #c86b6b;
    }
  }

  &__cal-body-span {
    vertical-align: middle;

    &--curr-day {
      text-decoration: underline;
    }

    &--sunday-dim {
      opacity: 0.5;
    }
  }
}

my-date-picker.ng-invalid.ng-touched {
  .datepicker__input {
    box-shadow: 0 0 0 1px $accent-color inset,
    0 3px 7px rgba(136, 139, 141, 0.20) inset !important;
  }
}

@keyframes selectorfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
