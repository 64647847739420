.checkbox {
  $this: & !default;
  display: inline-block;
  min-width: 150px;
  padding: 7px 0;

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 1px;
    height: 1px;
    margin: 0;
  }

  $short-time: 0.03s;
  $long-time: 0.19s;

  &__label {
    position: relative;
    display: block;
    padding: 5px 11px 5px 36px;
    will-change: color;
    transition: color 0.3s ease;

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      width: 0px;
      height: 2px;
      background: var(--checkbox-color);
      transform-origin: 0 50%;
      will-change: width;
    }

    &:before {
      transform: translate3d(6px, 13px, 0) rotate(45deg);
      transition: width #{$short-time} ease #{$long-time};
    }

    &:after {
      transform: translate3d(10px, 17px, 0) rotate(-45deg);
      transition: width #{$long-time} ease 0s;
    }
  }

  &__text:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: $border-radius;
    border: 1px solid #ccc;
    box-shadow: 0 3px 7px 0 rgba(136, 139, 141, 0.1) inset;
  }

  &__input:checked + #{$this}__label {
    color: var(--checkbox-color);

    &:before {
      width: 5.66px;
      transition: width #{$short-time} ease 0.1s;
    }

    &:after {
      width: 13.5px;
      transition: width #{$long-time} ease #{$short-time + 0.1s};
    }

    #{$this}__text:before {
      border: 2px solid var(--checkbox-color);
      box-shadow: 0 4px 16px 0 rgba(69, 69, 69, 0.1);
    }
  }

  &--vertical {
    display: block;
    min-width: auto;
  }

  &--without-text {
    min-width: 25px;

    #{$this}__label {
      width: 25px;
      height: 25px;
      padding: 0;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--box {
    width: 100%;
    padding: 0;
    color: #454545;
    text-align: center;
    cursor: pointer;

    #{$this}__label {
      padding: 15px 10px 15px 50px;
      border: 2px solid #ccc;
      border-radius: $border-radius;
      user-select: none;

      transition: color 0.3s ease,
      border-color 0.3s ease;

      &:before,
      &:after {
        top: 50%;
        left: 12px;
        margin-top: -13px;
      }
    }

    #{$this}__input:checked + #{$this}__label {
      border-color: var(--checkbox-color);
      box-shadow: 0 4px 16px 0 rgba(69, 69, 69, 0.1);
    }

    #{$this}__text:before {
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
    }
  }

  &--box-full {
    height: 100%;

    #{$this}__label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
    }
  }

  &--disabled {
    pointer-events: none;

    #{$this}__text {
      color: #b1b3b3;

      &:before {
        background: #f6f6f6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALElEQVQYV2NkwALevn37nxFdHCQoLCzMiCIBEwQphksgC8Il0AXBEtgEQRIA6XcXzRBgFUoAAAAASUVORK5CYII=) repeat;
      }

      &:before,
      &:after {
        border-color: #b1b3b3 !important;
      }
    }

    #{$this}__label:before,
    #{$this}__label:after {
      background: #b1b3b3;
    }
  }

  &--error {
    #{$this}__text {
      color: $error-color;

      &:before,
      &:after {
        border-color: $error-color !important;
      }
    }

    #{$this}__label:before,
    #{$this}__label:after {
      background: $error-color;
    }
  }
}
