@import 'utils/vars';

:root {
  /* palette */
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --alert-color: #{$alert-color};
  --disabled-color: #{$input-disabled-color};

  /* fonts */
  --header-font-family: #{$header-font-family};
  --base-font-family: #{$base-font-family};
  --base-font-size: #{$base-font-size}; // 1rem - 16px
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-heavy: 900;

  /* components */
  --progress-modal-progress-bar-color: #{$primary-color};
  --set-media-attributes-color: #{$primary-color};
  --list-item-color: #{$accent-color};
  --header-color: #{$header-color};
  --radio-color: #{$primary-color};
  --link-color: #{$accent-color};
  --create-attribute-modal-list-item-border-color: #dbd0ec;
  --share-products-modal-border-color: #{lighten($primary-color, 22%)};
  --share-products-modal-box-shadow-color: #{rgba($primary-color, 0.4)};
  --bulk-actions-list-box-shadow-color: #{rgba($primary-color, 0.3)};
  --checkbox-color: #{$primary-color};
  --blockquote-color: #{rgba(240, 85, 135, 0.25)};
  --simple-list-font-weight: 600;
  --section-title-font-weight: inherit;
  --display-attribute-font-weight: 600;
  --product-info-heading-font-weight: 700;
  --product-info-heading-font-size: 28px;
  --accordion-font-weight: 700;
  --modal-title-font-weight: 600;
  --display-partners-font-weight: var(--font-weight-heavy);

  /* button */
  --button-font-family: #{$btn-font-family};
  --button-font-weight: 600;
  --button-box-shadow: #{0 0 0 1px $primary-color inset, 0 4px 16px rgba(175, 150, 212, 0.25)};
  --button-hover-box-shadow: #{0 0 0 1px $primary-color inset, 0 4px 16px rgba(175, 150, 212, 0.35)};
  --button-normal-padding: #{$btn-normal-padding};
  --button-border-radius: #{$border-radius};
  --button-min-width: unset;
  /** inverted **/
  --button-inverted-border-color: #{none};
  /** primary **/
  --button-primary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.25)};
  --button-primary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.35)};
  --button-primary-hover-background-color: #{lighten($btn-primary-bg-color, 5%)};
  /** primary inverted **/
  --button-primary-inverted-box-shadow: #{inherit};
  --button-primary-inverted-color: #{inherit};
  --button-primary-inverted-background-color: #{inherit};
  --button-primary-inverted-hover-box-shadow: #{inherit};
  --button-primary-inverted-hover-background-color: #{inherit};
  /** secondary **/
  --button-secondary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.25)};
  --button-secondary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.35)};
  --button-secondary-hover-background-color: #{lighten($btn-secondary-bg-color, 5%)};
  /** secondary inverted **/
  --button-secondary-inverted-box-shadow: #{0 0 0 1px $btn-secondary-bg-color inset, 0 4px 16px rgba(0, 40, 108, 0.25)};
  --button-secondary-inverted-color: #{$btn-secondary-bg-color};
  --button-secondary-inverted-hover-box-shadow: #{0 0 0 1px lighten($btn-secondary-bg-color, 5%) inset, 0 4px 16px rgba(0, 40, 108, 0.35)};

  /* colorpicker */
  --colorpicker-box-shadow-color: #{rgba($primary-color, 0.3)};
  --colorpicker-input-box-shadow-color: #{$primary-color};
  --colorpicker-border-color: #{$primary-color};

  /* datepicker */
  --datepicker-car-head-cell-color: #{#ece6f4};
  --datepicker-header-today-color: #{#ece6f4};
  --datepicker-header-today-hover-color: #{#d7cae9};
  --datepicker-header-button-color: #{$accent-color};
  --datepicker-car-body-cell-curr-month-hover-color: #{#ece6f4};
  --datepicker-car-body-cell-selected-day-color: #{#d7cae9};

  /* input */
  --input-focus-box-shadow-start-color: #{$input-focus-border-color};
  --input-focus-box-shadow-end-color: #{rgba(69, 69, 69, 0.15)};
  --input-focus-color: #{$input-focus-color};

  /* notification */
  --notification-success-color: #{$success-color};
  --notification-alert-color: #{$alert-color};
  --notification-error-color: #{$error-color};
  --notification-success-box-shadow-color: #{rgba(113, 183, 144, 0.5)};
  --notification-alert-box-shadow-color: #{rgba(0, 172, 236, 0.5)};
  --notification-error-box-shadow-color: #{rgba(229, 15, 35, 0.5)};

  /* select */
  --select-tag-box-shadow-color: #{#52886a};
  --select-dropdown-selected-color: #{lighten($primary-color, 20%)};

  /* statuses */
  --statuses-font-weight: 600;
  --statuses-border-color: #{$primary-color};
  --statuses-color: #{$primary-color};
  --statuses-info-border-color: #{$secondary-color};
  --statuses-info-box-shadow-color: #{rgba(113, 183, 144, 0.25)};
  --statuses-info-color: #{$secondary-color};
  --statuses-active-background-color: #{$secondary-color};
  --statuses-active-border-color: #{$secondary-color};
  --statuses-active-color: #{#fff};
  --statuses-alert-border-color: #{$alert-color};
  --statuses-alert-color: #{$alert-color};
  --statuses-circular-new-border-color: #{$secondary-color};
  --statuses-circular-new-box-shadow-color: #{rgba(113, 183, 144, 0.25)};
  --statuses-circular-new-color: #{$secondary-color};
  --statuses-circular-updated-border-color: #{$info-color};
  --statuses-circular-updated-background-color: #{$info-color};
  --statuses-circular-updated-box-shadow-color: #{rgba(0, 164, 222, 0.25)};

  /* steps section group */
  --steps-section-group-nav-item-done-color: #{#ede8f5};
  --steps-section-group-nav-item-active-color: #{$secondary-color};

  /* table */
  --table-hover-color: #{#ece6f4};
  --table-animation-color: #{#b4dabf};

  /* tabs section group */
  --tabs-section-group-content-inner-border-color: #{$primary-color};
  /** violet **/
  --tabs-section-group-nav-item-violet-color: #{$primary-color};
  /** violet active **/
  --tabs-section-group-nav-item-active-violet-border-color: #{$primary-color};
  --tabs-section-group-nav-item-active-violet-text-color: #{$primary-color};
  --tabs-section-group-nav-item-active-violet-background-color: #{inherit};
  /** green **/
  --tabs-section-group-nav-item-green-background-color: #{#6bbe4a};
  --tabs-section-group-nav-item-green-border-color: #{#6bbe4a};
  /** green active **/
  --tabs-section-group-nav-item-active-green-border-color: #{#6BBE4A};
  --tabs-section-group-nav-item-active-green-background-color: #{inherit};
  --tabs-section-group-nav-item-active-green-text-color: #{#6BBE4A};
  /** item **/
  --tabs-section-group-nav-item-border: #{1px solid $primary-color};
  --tabs-section-group-nav-item-border-radius: #{inherit};
  --tabs-section-group-nav-item-background-color: #{$light-gray-color};
  --tabs-section-group-nav-item-text-color: #{#888b8d};
  --tabs-section-group-nav-item-text-font-weight: 600;
  --tabs-section-group-nav-item-counter-background-color: #{$dark-gray-color};
  /** item active **/
  --tabs-section-group-nav-item-active-background-color: #{#fff};
  --tabs-section-group-nav-item-active-border-bottom: #{inherit};
  --tabs-section-group-nav-item-active-text-color: #{$base-color};
  --tabs-section-group-nav-item-active-counter-background-color: #{$primary-color};
  --tabs-section-group-nav-item-active-counter-border: #{inherit};

  /* wrapper */
  --wrapper-background-color: #{$primary-color};
  --wrapper-menu-item-active-color: #{darken($primary-color, 10%)};
  --wrapper-menu-item-hover-color: #{darken($primary-color, 10%)};
  --wrapper-logo-height: 30px;

  /* wysiwyg */
  --wysiwig-anchor-color: #{$accent-color};
  --wysiwig-anchor-hover-color: #{$accent-darker-color};
}

body {
  // Declared in <body> instead of :root so they recalculate on --base-font-size change
  --font-size-lg: calc(var(--base-font-size) / 8 * 9); // 18px
  --font-size-sm: calc(var(--base-font-size) / 8 * 7); // 14px
  --font-size-xs: calc(var(--base-font-size) / 8 * 6); // 12px
  --font-size-xxs: calc(var(--base-font-size) / 8 * 5); // 10px
}

body.medxsync-theme {
  /* palette */
  --primary-color: #{$medxsync-primary-color};
  --secondary-color: #{$medxsync-secondary-color};
  --alert-color: #{$medxsync-alert-color};

  /* components */
  --progress-modal-progress-bar-color: #{$medxsync-primary-color};
  --set-media-attributes-color: #{$medxsync-secondary-color};
  --list-item-color: #{$medxsync-secondary-color};
  --header-color: #{$medxsync-primary-color};
  --radio-color: #{$medxsync-primary-color};
  --link-color: #{$medxsync-secondary-color};
  --create-attribute-modal-list-item-border-color: #d4def9;
  --share-products-modal-border-color: #{lighten($medxsync-primary-color, 22%)};
  --share-products-modal-box-shadow-color: #{rgba($medxsync-primary-color, 0.4)};
  --bulk-actions-list-box-shadow-color: #{rgba($medxsync-primary-color, 0.3)};
  --checkbox-color: #{$medxsync-primary-color};
  --blockquote-color: #{rgba($medxsync-secondary-color, 0.25)};

  /* button */
  --button-box-shadow: #{0 0 0 1px $medxsync-primary-color inset, 0 4px 16px rgba(175, 150, 212, 0.25)};
  --button-hover-box-shadow: #{0 0 0 1px $medxsync-primary-color inset, 0 4px 16px rgba(175, 150, 212, 0.35)};
  /** inverted **/
  --button-inverted-border-color: #{$medxsync-btn-primary-bg-color};
  /** primary **/
  --button-primary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.25)};
  --button-primary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.35)};
  --button-primary-hover-background-color: #{lighten($medxsync-btn-primary-bg-color, 5%)};
  /** primary inverted **/
  --button-primary-inverted-box-shadow: #{0 0 0 1px $medxsync-btn-primary-bg-color inset, 0 4px 16px rgba(133, 200, 0, 0.35)};
  --button-primary-inverted-color: #{$medxsync-btn-primary-bg-color};
  --button-primary-inverted-background-color: #{transparent};
  --button-primary-inverted-hover-box-shadow: #{0 0 0 1px lighten($medxsync-btn-primary-bg-color, 5%) inset, 0 4px 16px rgba(133, 200, 0, 0.45)};
  --button-primary-inverted-hover-background-color: #{transparent};
  /** secondary **/
  --button-secondary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.25)};
  --button-secondary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.35)};
  --button-secondary-hover-background-color: #{lighten($medxsync-btn-secondary-bg-color, 5%)};
  /** secondary inverted **/
  --button-secondary-inverted-box-shadow: #{0 0 0 1px $medxsync-btn-secondary-bg-color inset, 0 4px 16px rgba(133, 200, 0, 0.35)};
  --button-secondary-inverted-color: #{$medxsync-btn-secondary-bg-color};
  --button-secondary-inverted-hover-box-shadow: #{0 0 0 1px lighten($medxsync-btn-secondary-bg-color, 5%) inset, 0 4px 16px rgba(133, 200, 0, 0.45)};

  /* colorpicker */
  --colorpicker-box-shadow-color: #{rgba($medxsync-primary-color, 0.3)};
  --colorpicker-input-box-shadow-color: #{$medxsync-primary-color};
  --colorpicker-border-color: #{$medxsync-primary-color};

  /* datepicker */
  --datepicker-car-head-cell-color: #{#e7ecf9};
  --datepicker-header-today-color: #{#e7ecf9};
  --datepicker-header-today-hover-color: #{#d4def9};
  --datepicker-header-button-color: #{$primary-color}; // probably a mistake
  --datepicker-car-body-cell-curr-month-hover-color: #{#e6e8f4};
  --datepicker-car-body-cell-selected-day-color: #{#d1d8f9};

  /* input */
  --input-focus-box-shadow-start-color: #{$medxsync-input-focus-border-color};
  --input-focus-box-shadow-end-color: #{rgba(69, 69, 69, 0.15)};
  --input-focus-color: #{$medxsync-input-focus-color};

  /* select */
  --select-tag-box-shadow-color: #{lighten($medxsync-secondary-color, 20%)};
  --select-dropdown-selected-color: #{lighten($medxsync-primary-color, 50%)};

  /* statuses */
  --statuses-border-color: #{$medxsync-primary-color};
  --statuses-color: #{$medxsync-primary-color};
  --statuses-info-border-color: #{$medxsync-secondary-color};
  --statuses-info-box-shadow-color: #{rgba($medxsync-secondary-color, 0.25)};
  --statuses-info-color: #{$medxsync-secondary-color};
  --statuses-active-background-color: #{$medxsync-secondary-color};
  --statuses-active-border-color: #{$medxsync-secondary-color};
  --statuses-alert-border-color: #{$medxsync-alert-color};
  --statuses-alert-color: #{$medxsync-alert-color};

  /* steps section group */
  --steps-section-group-nav-item-done-color: #{lighten($medxsync-secondary-color, 30%)};
  --steps-section-group-nav-item-active-color: #{$medxsync-secondary-color};

  /* table */
  --table-hover-color: #{#e7ecf9};
  --table-animation-color: #{#93d7ef};

  /* tabs section group */
  --tabs-section-group-content-inner-border-color: #{$medxsync-primary-color};
  /** violet **/
  --tabs-section-group-nav-item-violet-color: #{$medxsync-primary-color};
  /** violet active **/
  --tabs-section-group-nav-item-active-violet-border-color: #{$medxsync-primary-color};
  --tabs-section-group-nav-item-active-violet-background-color: #{#fff};
  --tabs-section-group-nav-item-active-violet-text-color: #{$medxsync-primary-color};
  /** item **/
  --tabs-section-group-nav-item-border: #{solid 1px $medxsync-primary-color};
  /** item active **/
  --tabs-section-group-nav-item-active-counter-background-color: #{$medxsync-primary-color};

  /* wrapper */
  --wrapper-background-color: #{$medxsync-primary-color};
  --wrapper-menu-item-active-color: #{darken($medxsync-primary-color, 10%)};
  --wrapper-menu-item-hover-color: #{darken($medxsync-primary-color, 10%)};

  /* wysiwyg */
  --wysiwig-anchor-color: #{$medxsync-secondary-color};
  --wysiwig-anchor-hover-color: #{darken($medxsync-secondary-color, 10%)};
}

body.municipality-theme {
  /* palette */
  --primary-color: #{$municipality-primary-color};
  --secondary-color: #{$municipality-secondary-color};
  --alert-color: #{$municipality-alert-color};

  /* components */
  --progress-modal-progress-bar-color: #{$municipality-secondary-color};
  --set-media-attributes-color: #{$municipality-secondary-color};
  --list-item-color: #{$municipality-secondary-color};
  --header-color: #{$municipality-primary-color};
  --radio-color: #{$municipality-secondary-color};
  --link-color: #{$municipality-secondary-color};
  --create-attribute-modal-list-item-border-color: #{$municipality-primary-color};
  --share-products-modal-border-color: #{lighten($municipality-primary-color, 22%)};
  --share-products-modal-box-shadow-color: #{rgba($municipality-primary-color, 0.4)};
  --bulk-actions-list-box-shadow-color: #{rgba($municipality-primary-color, 0.3)};
  --checkbox-color: #{$municipality-secondary-color};
  --blockquote-color: #{rgba($municipality-secondary-color, 0.25)};

  /* button */
  --button-box-shadow: #{0 0 0 1px $municipality-primary-color inset, 0 4px 16px rgba(87, 115, 59, 0.25)};
  --button-hover-box-shadow: #{0 0 0 1px $municipality-primary-color inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  /** inverted **/
  --button-inverted-border-color: #{$municipality-btn-primary-bg-color};
  /** primary **/
  --button-primary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(87, 115, 59, 0.25)};
  --button-primary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  --button-primary-hover-background-color: #{lighten($municipality-btn-primary-bg-color, 5%)};
  /** primary inverted **/
  --button-primary-inverted-box-shadow: #{0 0 0 1px $municipality-btn-primary-bg-color inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  --button-primary-inverted-color: #{$municipality-btn-primary-bg-color};
  --button-primary-inverted-background-color: #{transparent};
  --button-primary-inverted-hover-box-shadow: #{0 0 0 1px lighten($municipality-btn-primary-bg-color, 5%) inset, 0 4px 16px rgba(87, 115, 59, 0.45)};
  --button-primary-inverted-hover-background-color: #{transparent};
  /** secondary **/
  --button-secondary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(225, 172, 93, 0.25)};
  --button-secondary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(225, 172, 93, 0.35)};
  --button-secondary-hover-background-color: #{lighten($municipality-btn-secondary-bg-color, 5%)};
  /** secondary inverted **/
  --button-secondary-inverted-box-shadow: #{0 0 0 1px $municipality-btn-secondary-bg-color inset, 0 4px 16px rgba(225, 172, 93, 0.35)};
  --button-secondary-inverted-color: #{$municipality-btn-secondary-bg-color};
  --button-secondary-inverted-hover-box-shadow: #{0 0 0 1px lighten($municipality-btn-secondary-bg-color, 5%) inset, 0 4px 16px rgba(225, 172, 93, 0.45)};

  /* colorpicker */
  --colorpicker-box-shadow-color: #{rgba($municipality-secondary-color, 0.3)};
  --colorpicker-input-box-shadow-color: #{$municipality-secondary-color};
  --colorpicker-border-color: #{$municipality-secondary-color};

  /* datepicker */
  --datepicker-car-head-cell-color: #{#e7ecf9};
  --datepicker-header-today-color: #{#e7ecf9};
  --datepicker-header-today-hover-color: #{#d4def9};
  --datepicker-header-button-color: #{$primary-color}; // probably a mistake
  --datepicker-car-body-cell-curr-month-hover-color: #{#e6e8f4};
  --datepicker-car-body-cell-selected-day-color: #{#d1d8f9};

  /* input */
  --input-focus-box-shadow-start-color: #{$municipality-input-focus-border-color};
  --input-focus-box-shadow-end-color: #{rgba($municipality-input-focus-border-color, 0.15)};
  --input-focus-color: #{$municipality-input-focus-color};

  /* notification FIXME these were defined previously but unused */
  //--notification-success-color: #{$municipality-primary-color};
  //--notification-alert-color: #{$municipality-secondary-color};
  //--notification-error-color: #{$municipality-alert-color};
  //--notification-success-box-shadow-color: #{rgba($municipality-primary-color, 0.5)};
  //--notification-alert-box-shadow-color: #{rgba($municipality-secondary-color, 0.5)};
  //--notification-error-box-shadow-color: #{rgba($municipality-alert-color, 0.5)};

  /* select */
  --select-tag-box-shadow-color: #{lighten($municipality-secondary-color, 20%)};
  --select-dropdown-selected-color: #{lighten($municipality-primary-color, 50%)};

  /* statuses */
  --statuses-circular-new-border-color: #{$municipality-secondary-color};
  --statuses-circular-new-box-shadow-color: #{rgba($municipality-secondary-color, 0.25)};
  --statuses-circular-new-color: #{$municipality-secondary-color};
  --statuses-circular-updated-border-color: #{$municipality-primary-color};
  --statuses-circular-updated-background-color: #{$municipality-primary-color};
  --statuses-circular-updated-box-shadow-color: #{rgba($municipality-primary-color, 0.25)};

  /* steps section group */
  --steps-section-group-nav-item-done-color: #{lighten($municipality-secondary-color, 30%)};
  --steps-section-group-nav-item-active-color: #{$municipality-secondary-color};

  /* table */
  --table-hover-color: #{lighten($municipality-header-cell-background, 50%)};
  --table-animation-color: #{lighten($municipality-header-cell-background, 20%)};

  /* tabs section group */
  --tabs-section-group-content-inner-border-color: #{$municipality-primary-color};
  /** violet **/
  --tabs-section-group-nav-item-violet-color: #{$municipality-secondary-color};
  /** violet active **/
  --tabs-section-group-nav-item-active-violet-border-color: #{$municipality-secondary-color};
  --tabs-section-group-nav-item-active-violet-background-color: #{#fff};
  --tabs-section-group-nav-item-active-violet-text-color: #{$municipality-secondary-color};
  /** green **/
  --tabs-section-group-nav-item-green-background-color: #{$municipality-primary-color};
  --tabs-section-group-nav-item-green-border-color: #{$municipality-primary-color};
  /** green active **/
  --tabs-section-group-nav-item-active-green-border-color: #{$municipality-primary-color};
  --tabs-section-group-nav-item-active-green-background-color: #{#fff};
  --tabs-section-group-nav-item-active-green-text-color: #{$municipality-primary-color};
  /** item **/
  --tabs-section-group-nav-item-border: #{solid 1px $municipality-primary-color};
  /** item active **/
  --tabs-section-group-nav-item-active-counter-background-color: #{$municipality-primary-color};

  /* wrapper */
  --wrapper-background-color: #{lighten($municipality-primary-color, 40%)};
  --wrapper-menu-item-active-color: #{darken($municipality-primary-color, 0%)};
  --wrapper-menu-item-hover-color: #{darken($municipality-primary-color, 10%)};
  --wrapper-logo-height: auto;

  /* wysiwyg */
  --wysiwig-anchor-color: #{$municipality-secondary-color};
  --wysiwig-anchor-hover-color: #{darken($municipality-secondary-color, 10%)};
}

body.fta-theme {
  /* palette */
  --primary-color: #{$fta-primary-color};
  --secondary-color: #{$fta-secondary-color};
  --alert-color: #{$fta-alert-color};
  --font-weight-bold: 500;
  --font-weight-extra-bold: 600;
  --font-weight-heavy: 700;

  /* fonts */
  --header-font-family: #{$fta-base-font-family};
  --base-font-family: #{$fta-base-font-family};
  --base-font-size: #{$base-font-size * 0.90};

  /* components */
  --progress-modal-progress-bar-color: #{$fta-secondary-color};
  --set-media-attributes-color: #{$fta-secondary-color};
  --list-item-color: #{$fta-secondary-color};
  --header-color: #{$fta-primary-color};
  --radio-color: #{$fta-secondary-color};
  --link-color: #{$fta-secondary-color};
  --create-attribute-modal-list-item-border-color: #{$fta-primary-color};
  --share-products-modal-border-color: #{lighten($fta-primary-color, 22%)};
  --share-products-modal-box-shadow-color: #{rgba($fta-primary-color, 0.4)};
  --bulk-actions-list-box-shadow-color: #{rgba($fta-primary-color, 0.3)};
  --checkbox-color: #{$fta-primary-color};
  --blockquote-color: #{rgba($fta-secondary-color, 0.25)};
  --simple-list-font-weight: 500;
  --section-title-font-weight: 500;
  --display-attribute-font-weight: 500;
  --product-info-heading-font-weight: 600;
  --product-info-heading-font-size: calc(28px * 0.95);
  --accordion-font-weight: 600;
  --modal-title-font-weight: 500;
  --display-partners-font-weight: 600;

  /* button */
  --button-font-family: var(--base-font-family);
  --button-font-weight: 500;
  --button-box-shadow: #{0 0 0 1px $fta-primary-color inset, 0 4px 16px rgba(87, 115, 59, 0.25)};
  --button-hover-box-shadow: #{0 0 0 1px $fta-primary-color inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  /** inverted **/
  --button-inverted-border-color: #{$fta-btn-primary-bg-color};
  /** primary **/
  --button-primary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(87, 115, 59, 0.25)};
  --button-primary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  --button-primary-hover-background-color: #{lighten($fta-btn-primary-bg-color, 5%)};
  /** primary inverted **/
  --button-primary-inverted-box-shadow: #{0 0 0 1px $fta-btn-primary-bg-color inset, 0 4px 16px rgba(87, 115, 59, 0.35)};
  --button-primary-inverted-color: #{$fta-btn-primary-bg-color};
  --button-primary-inverted-background-color: #{transparent};
  --button-primary-inverted-hover-box-shadow: #{0 0 0 1px lighten($fta-btn-primary-bg-color, 5%) inset, 0 4px 16px rgba(87, 115, 59, 0.45)};
  --button-primary-inverted-hover-background-color: #{transparent};
  /** secondary **/
  --button-secondary-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(225, 172, 93, 0.25)};
  --button-secondary-hover-box-shadow: #{0 0 0 1px transparent inset, 0 4px 16px rgba(225, 172, 93, 0.35)};
  --button-secondary-hover-background-color: #{lighten($fta-btn-secondary-bg-color, 5%)};
  /** secondary inverted **/
  --button-secondary-inverted-box-shadow: #{0 0 0 1px $fta-btn-secondary-bg-color inset, 0 4px 16px rgba(225, 172, 93, 0.35)};
  --button-secondary-inverted-color: #{$fta-btn-secondary-bg-color};
  --button-secondary-inverted-hover-box-shadow: #{0 0 0 1px lighten($fta-btn-secondary-bg-color, 5%) inset, 0 4px 16px rgba(225, 172, 93, 0.45)};

  /* colorpicker */
  --colorpicker-box-shadow-color: #{rgba($fta-secondary-color, 0.3)};
  --colorpicker-input-box-shadow-color: #{$fta-secondary-color};
  --colorpicker-border-color: #{$fta-secondary-color};

  /* datepicker */
  --datepicker-car-head-cell-color: #{#e7ecf9};
  --datepicker-header-today-color: #{#e7ecf9};
  --datepicker-header-today-hover-color: #{#d4def9};
  --datepicker-header-button-color: #{$primary-color}; // probably a mistake
  --datepicker-car-body-cell-curr-month-hover-color: #{#e6e8f4};
  --datepicker-car-body-cell-selected-day-color: #{#d1d8f9};

  /* input */
  --input-focus-box-shadow-start-color: #{$fta-input-focus-border-color};
  --input-focus-box-shadow-end-color: #{rgba($fta-input-focus-border-color, 0.15)};
  --input-focus-color: #{$fta-input-focus-color};

  /* notification FIXME these were defined previously but unused */
  //--notification-success-color: #{$fta-primary-color};
  //--notification-alert-color: #{$fta-secondary-color};
  //--notification-error-color: #{$fta-alert-color};
  //--notification-success-box-shadow-color: #{rgba($fta-primary-color, 0.5)};
  //--notification-alert-box-shadow-color: #{rgba($fta-secondary-color, 0.5)};
  //--notification-error-box-shadow-color: #{rgba($fta-alert-color, 0.5)};

  /* select */
  --select-tag-box-shadow-color: #{lighten($fta-secondary-color, 20%)};
  --select-dropdown-selected-color: #{lighten($fta-primary-color, 50%)};

  /* statuses */
  --statuses-font-weight: 400;
  --statuses-border-color: #{$fta-primary-color};
  --statuses-color: #{$fta-primary-color};
  --statuses-info-border-color: #{lighten($fta-secondary-color, 30%)};
  --statuses-info-box-shadow-color: #{rgba(lighten($fta-secondary-color, 30%), 0.25)};
  --statuses-info-color: #{#000};
  --statuses-active-background-color: #{lighten($fta-secondary-color, 30%)};
  --statuses-active-border-color: #{lighten($fta-secondary-color, 30%)};
  --statuses-active-color: #{#313335};
  --statuses-alert-border-color: #{$fta-alert-color};
  --statuses-alert-color: #{$fta-alert-color};
  --statuses-circular-updated-border-color: #{$fta-primary-color};

  /* steps section group */
  --steps-section-group-nav-item-done-color: #{lighten($fta-secondary-color, 30%)};
  --steps-section-group-nav-item-active-color: #{$fta-primary-color};

  /* table */
  --table-hover-color: #{#CED2E2};
  --table-animation-color: #{lighten($fta-header-cell-background, 20%)};

  /* tabs section group */
  /** green **/
  --tabs-section-group-nav-item-green-background-color: #{$fta-primary-color};
  --tabs-section-group-nav-item-green-border-color: #{$fta-primary-color};
  /** green active **/
  --tabs-section-group-nav-item-active-green-border-color: #{$fta-primary-color};
  --tabs-section-group-nav-item-active-green-background-color: #{#fff};
  --tabs-section-group-nav-item-active-green-text-color: #{$fta-primary-color};
  /** item **/
  --tabs-section-group-nav-item-border: #{solid 1px #306190};
  --tabs-section-group-nav-item-border-radius: #{2px};
  --tabs-section-group-nav-item-counter-background-color: #{#83a0bc};
  --tabs-section-group-nav-item-text-font-weight: 500;
  /** item active **/
  --tabs-section-group-nav-item-active-border-bottom: #{none};
  --tabs-section-group-nav-item-active-counter-background-color: #{#306190};
  --tabs-section-group-nav-item-active-counter-border: #{solid 1px #306190};

  /* wrapper */
  --wrapper-background-color: #{$fta-primary-color};
  --wrapper-menu-item-active-color: #{$fta-secondary-color};
  --wrapper-menu-item-hover-color: #{darken($fta-primary-color, 10%)};
  --wrapper-logo-height: auto;

  /* wysiwyg */
  --wysiwig-anchor-color: #{$fta-secondary-color};
  --wysiwig-anchor-hover-color: #{darken($fta-secondary-color, 10%)};
}
