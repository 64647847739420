.accordion {
  $this: & !default;
  $space: 30px;

  margin-top: $space;

  &:last-of-type {
    margin-bottom: $space;
  }

  &__heading {
    position: relative;
    padding: 10px 15px;
    border-radius: $border-radius;
    background: $medium-gray-color;
    cursor: pointer;

    &:after {
      @include font-setup();
      content: $icon-chevron;
      position: absolute;
      top: 50%;
      right: 15px;
      color: $base-color;
      font-size: var(--font-size-lg);
      transform: translateY(-50%) rotate(0);
      transition: transform 0.250s ease;
    }
  }

  &__header {
    margin-bottom: 3px;
    font-weight: var(--accordion-font-weight);
    font-size: var(--font-size-lg);
  }

  &__meta {
    font-size: 13px;
  }

  &__meta-item {
    margin-top: 5px;
  }

  &__bold {
    font-weight: var(--accordion-font-weight);
  }

  &__state {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    color: #949494;
    font-family: var(--header-font-family);
    font-size: var(--font-size-xs);
  }

  &__content-wrapper {
    overflow: hidden;
  }

  &__content {
    padding-top: $space;
  }

  &__columns {
    display: flex;
  }

  &__column {
    width: 50%;

    &:nth-child(1) {
      padding-right: 15px;
      border-right: 1px solid $medium-gray-color;
    }

    &:nth-child(2) {
      padding-left: 15px;
    }
  }

  &__row {
    border-radius: $border-radius;
    padding: 10px 15px;

    &:nth-child(even) {
      background-color: $light-gray-color;
    }
  }

  &__topic {
    display: block;
    font-size: var(--font-size-sm);
  }

  &__subname {
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $medium-gray-color;
  }

  &--open & {
    &__heading:after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--disabled & {
    &__heading {
      cursor: default;

      &:after {
        content: none;
      }
    }
  }
}
