.modal {
  $this: & !default;
  position: relative;
  z-index: 1100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.250s ease;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &--disable-animations {
    transition: none;
  }

  &--no-padding &__body {
    padding: 0;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1300;
    overflow: auto;
    display: block;
    padding: 32px;
    white-space: nowrap;
    text-align: center;

    & > * {
      text-align: left;
    }

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      height: 100%;
      width: 0;
      margin-right: -4px;
      vertical-align: middle;
    }
  }

  &__body {
    display: inline-block;
    position: relative;
    max-width: 100%;
    min-width: 480px;
    margin: auto;
    padding: 24px 64px;
    border-radius: $border-radius;
    background: #fff;
    vertical-align: middle;
    white-space: normal;
    transition: transform 0.3s ease;

    &--reject,
    &--preview {
      max-width: 1200px;
      width: 100%;
    }

    &--min-w-1000 {
      min-width: 1050px;
      max-width: 1200px;
      width: 100%;
    }

    &--max-w-1200 {
      width: 100%;
      max-width: 1200px;
    }
  }

  &__indicator {
    $width: 35px;
    $height: 18px;

    display: none;
    position: absolute;
    top: -99999px;
    height: $height;
    transition: left 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: calc(-1 * $width / 2);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 calc($width / 2) $height calc($width / 2);
      border-color: transparent transparent #ffffff transparent;
    }

    &--top,
    &--bottom {
      display: block;
    }

    &--top {
      top: -$height;
    }

    &--bottom {
      top: auto;
      bottom: -$height;

      &:before {
        border-width: $height calc($width / 2) 0 calc($width / 2);
        /* The same color as modal guide pagination from below */
        border-color: #efefef transparent transparent transparent;
      }
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary-color);
    font-size: 24px;
    cursor: pointer;


    &:before {
      display: block;
      transform: rotate(0deg);
      transition: transform 0.2s ease-out;
    }

    &:hover:before {
      transform: rotate(-180deg);
    }
  }

  &__header {
    padding-bottom: 24px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--primary-color);
    color: $base-color;
    text-align: center;
    text-transform: capitalize;
  }

  &__title {
    font-family: var(--header-font-family);
    font-weight: var(--modal-title-font-weight);
  }

  &__title + &__description {
    margin-top: 15px;
  }

  &__description {
    font-family: var(--base-font-family);
  }

  &__heading {
    margin: 20px 0;
  }

  &__loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--fixed-size &__body {
    width: 80%;
  }

  &__actions {
    padding: 35px 0 0;
    text-align: center;
  }

  &__dim {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1200;
    opacity: 0.4;
  }

  &__dim-side {
    position: absolute;
    z-index: 1200;
    background-color: rgb(42, 37, 48);
    transition: 0.3s ease-out;

    &--top {
      top: 0;
      right: 0;
      left: 0;
      height: 50%;
    }

    &--right {
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
    }

    &--bottom {
      top: 50%;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &--left {
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%;
    }
  }

  &__dim--scrolling &__dim-side {
    transition-duration: 0s;
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  &__preview-images {
    display: flex;
  }

  &__preview-images-container {
    margin: 0 -5px;
  }

  &__preview-image {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

body.modal-open {
  overflow: hidden;
}

.guide {
  width: 690px;
  color: $base-color;
  text-align: center;
  line-height: 1.5;

  &__skip {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  &__skip-text {
    color: $accent-color;
    font-size: var(--font-size-sm);
    text-decoration: underline;
    cursor: pointer;
  }

  &__content {
    padding: 0 95px;
  }

  &__heading {
    margin: 1.5em 0;
    font-family: var(--header-font-family);
    font-size: 20px;
    font-weight: var(--font-weight-bold);
  }

  &__description {
    margin: 1em 0;
  }

  &__actions {
    width: 310px;
    margin: 0 auto;
  }

  &__spacer {
    height: 1px;
    background: lighten($gray-color, 15%);
    margin: 25px 0;
  }

  $buttons-space: 15px;

  &__buttons {
    display: flex;
    margin: 25px (-$buttons-space);

    & > .btn {
      margin: 0 $buttons-space;
    }
  }

  &__info {
    color: $gray-color;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: 25px;
    background: #efefef;
  }

  &__pagination-step {
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid $gray-color;
    border-radius: 50%;
    background: #fff;

    &--active {
      border-color: $secondary-color;
      background: $secondary-color;
    }

    &--visited {
      border-color: #b0d3c0;
      background: #b0d3c0;
    }
  }
}
