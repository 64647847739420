.product-preview {
  $this: &!default;
  position: relative;
  margin-top: 32px;

  &--loading {
    #{$this}__accordion {
      opacity: 0.7;
    }
  }

  &__accordion {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  &__button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      margin: 0 3px;
    }

    &--margin-10 {
      margin: 10px 0;
    }
  }

  &__section {
    margin-bottom: 64px;

    &--margin-bottom-32 {
      margin-bottom: 32px;
    }
  }

  &__section-title {
    margin-bottom: 32px;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__row {
    @include row();

    & + & {
      margin-top: 32px;
    }
  }

  &__group {
    @include column-classes('--cols-');
  }

  &__expandable-content {
    padding-top: 32px;
    padding-left: 32px;

    &.ng-animating {
      overflow: hidden;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;

    &-checkbox {
      position: absolute !important;
      top: 5px;
      right: -15px;
    }

    &-container {
      position: relative;
      max-width: 800px;
      margin: auto;

      &--selectable {
        position: relative;
        cursor: pointer;

        &:hover:after {
          content: '';
          z-index: 2;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($primary-color, 0.4);
        }
      }
    }
  }

  &__completeness {
    padding: 15px 10px 10px;
    font-weight: 600;
    text-transform: capitalize;
  }

  &__completeness-label {
    margin-bottom: 10px;
  }
}
