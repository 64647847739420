.section-title {
  position: relative;
  display: block;
  min-height: 40px;
  padding: 10px 15px;
  border: none;
  border-radius: $border-radius;
  background-color: $medium-gray-color;
  color: $base-color;
  font-family: var(--header-font-family);
  font-size: var(--font-size-lg);
  line-height: 20px;
  text-transform: capitalize;
  font-weight: var(--section-title-font-weight);

  &__meta {
    display: none;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    color: #949494;
    font-size: var(--font-size-xs);
    font-weight: 400;
  }

  &--expandable {
    padding-right: 155px;
    cursor: pointer;

    &:after {
      @include font-setup();
      content: $icon-chevron;
      position: absolute;
      top: 50%;
      right: 15px;
      color: $base-color;
      transform: translateY(-50%) rotate(0);
      transition: transform 0.250s ease;
    }
  }

  &--expandable &__meta {
    display: block;
  }

  &--is-visible:after {
    transform: translateY(-50%) rotate(180deg);
  }

  &--is-invalid {
    color: $error-color;

    &:before {
      @include font-setup();
      content: $icon-error;
      font-size: var(--base-font-size);
    }
  }

  &--no-after:after {
    display: none;
  }
}
