.message {
  $this: & !default;
  display: flex;
  min-height: 64px;
  padding: 14px;
  background-color: #fff;
  border: 2px solid #cdcdcd;
  border-radius: 5px;
  justify-content: space-between;

  &--small {
    font-size: var(--font-size-sm);
    min-height: 0;
    padding: 10px 15px;
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  &__container {
    margin-top: 20px;
  }

  &__icon-font {
    display: flex;
    align-items: center;
    margin-right: 14px;
    font-size: 32px;
    font-weight: 400;
  }

  &__not-available {
    display: flex;
    align-items: center;
    width: 32px;
    margin-right: 14px;
    font-weight: 600;

    &:before {
      content: 'n/a';
    }
  }

  &__wrapper {
    margin-top: 30px;
  }

  &__content-container {
    display: flex;
    min-width: 50%;

    &--back {
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;

    &--limited {
      max-width: 950px;

      @media (max-width: 1600px) {
        max-width: 800px;
      }

      @media (max-width: 1350px) {
        max-width: 600px;
      }
    }

    > * + * {
      margin-top: 10px;
    }

    &-container {
      display: flex;
      width: 100%;

      &--back {
        width: 100%;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    &--single {
      justify-content: flex-end;
    }
  }

  &__title {
    font-size: var(--base-font-size);
    font-weight: 600;
    text-transform: uppercase;
  }

  &__icon {
    font-size: 20px;
    color: $primary-color;

    &--alert {
      color: $alert-color;
    }
  }

  &__icon-back {
    margin: 0 14px 0 20px;
  }

  &--positive {
    background-color: #f7fbf9;
    border-color: #39b88f;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: #39b88f;
    }
  }

  &--negative {
    background-color: #fff6f9;
    border-color: #fe6998;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: #fe6998;
    }
  }

  &--info {
    background-color: #f5fdff;
    border-color: #00b7e0;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: #00b7e0;
    }
  }

  &--warning {
    background-color: #fffbf5;
    border-color: #ffb21d;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: #ffb21d;
    }
  }

  &--primary {
    background-color: lighten($primary-color, 26.5%);
    border-color: $primary-color;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: $primary-color;
    }
  }

  &--disabled {
    background-color: $light-gray-color;
    border-color: $input-disabled-color;
    opacity: 0.7;

    #{$this}__icon-font,
    #{$this}__not-available,
    #{$this}__title {
      color: $input-disabled-color;
    }
  }
}
