.categories {
  margin-top: 30px;

  &__item {
    display: block;
    margin: 5px 0;
    font-size: 14.5px;
    color: $dark-gray-color;

    button {
      min-height: 0;
      padding: 0;
    }
  }

  &__term,
  &__multicolor {
    position: relative;
    padding: 7px 0;
    display: block;

    &:not(:last-child):before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 4px;
      width: 22px;
      height: 20px;
      margin-top: -5px;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }

  &__last {
    color: $base-color;
    font-size: var(--base-font-size);
    font-weight: var(--font-weight-bold);

    &:before {
      content: '- ';
    }
  }

  @for $i from 1 through 10 {
    $left: ($i - 1) * 30px + 4px;
    &__term:nth-child(#{$i}) {
      @if $i > 1 {
        margin-left: $left;
      }

      &:last-child {
        color: $base-color;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
        padding-top: 5px;
      }
    }
  }
}
