.loader {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  cursor: default;
  pointer-events: auto;

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
    border-radius: 100%;
    border-top: 2px solid rgba(0, 0, 0, 0.15);
    border-right: 2px solid rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    border-left: 2px solid var(--primary-color);
    animation: loader .6s linear;
    animation-iteration-count: infinite;
  }

  &--big {
    width: 24px;
    height: 24px;

    &:before {
      width: 24px;
      height: 24px;
      margin: -12px 0 0 -12px;
    }
  }

  &--center {
    display: block;
    margin: {
      right: auto;
      left: auto;
    }
  }
}
