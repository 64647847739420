.input-group {
  position: relative;
  display: inline-flex;
  max-width: 100%;

  &--fluid {
    display: flex;
  }

  /*
   * Addon
   */
  &__addon {
    z-index: 2;
    flex: 0 0 auto;
    border-right-width: 0;
    border-left-width: 0;

    &:first-child {
      border-left-width: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-right-width: 1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    & + & {
      border-left-width: 1px;
    }

    &--fixed48 {
      flex: 0 0 48px;
      text-align: left;
    }
  }

  /*
   * Input
   */
  &__input {
    flex: 1 1 auto;
    min-width: 0;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
