.history-log {
  &__heading {
    margin: 20px 0 40px;
    font-weight: var(--font-weight-heavy);
    font-size: 20px;
    text-align: center;
  }

  &__row {
    display: flex;
    margin: 0 -16px;
  }

  &__column {
    width: 33.33333%;
    padding: 0 16px;
    text-align: center;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
  }

  &__icon {
    font-size: 36px;
    color: #af96d4;

    &--large {
      font-size: 52px;
    }

    &-content {
      display: flex;
      margin-left: 10px;
      font-size: var(--font-size-sm);
      font-weight: 100;
      flex-direction: column;
      justify-content: space-around;

      &--large {
        font-size: var(--base-font-size);
      }

      a {
        font-weight: 500;
      }
    }

    &-container {
      display: flex;
      margin-bottom: 10px;
      justify-content: center;
    }
  }

  &__result {
    font-size: 36px;
    font-weight: 100;
    text-transform: uppercase;
    color: $primary-color;

    &--small {
      font-size: var(--font-size-lg);
      word-break: break-all;
      text-transform: none;
    }
  }

  &__row-container {
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;

    &:last-of-type {
      border: none;
    }
  }

  &__status {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    transition: width 1s ease-in-out;

    &--success {
      z-index: 2;
      background: #29b540;
    }

    &--fail {
      z-index: 1;
      background: #e50f23;
      transition-delay: 1s;
    }

    &-bar {
      overflow: hidden;
      position: relative;
      height: 30px;
      max-width: 400px;
      margin: 20px auto;
      background: #f4f4f4;
      border-radius: 20px;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: var(--base-font-size);
    color: #666666;
    line-height: 20px;
  }

  &__tooltip {
    vertical-align: middle;
    margin-left: 20px;
    color: #af96d4;
    font-size: 22px;
  }
}
