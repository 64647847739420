// temporary hidden isThisProductAPack attribute
.fta-hidden-attrs {
  .form__row.form__row--indent-left-40 {
    &.isThisProductAPack {
      display: none;
    }
  }
}

.display-attribute.is-this-product-a-pack {
  display: none;
}
