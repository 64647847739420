input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.input {
  position: relative;
  z-index: 2;
  max-width: 100%;
  margin: 0;
  padding: $input-padding;
  outline: 0;
  border: none;
  border-radius: $border-radius;
  background-color: $input-default-bg-color;
  box-shadow: 0 0 0 1px $input-default-border-color inset,
  0 3px 7px rgba(136, 139, 141, 0.20) inset;
  color: $input-default-color;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  line-height: $input-line-height;
  text-align: left;

  &:focus {
    box-shadow: 0 0 0 2px var(--input-focus-box-shadow-start-color) inset,
    0 4px 16px var(--input-focus-box-shadow-end-color);
    color: var(--input-focus-color);
  }

  &::placeholder {
    @include placeholder();
  }

  &::-webkit-input-placeholder {
    @include placeholder();
  }

  &::-moz-placeholder {
    @include placeholder();
    white-space: normal;
  }

  &:-ms-input-placeholder {
    @include placeholder();
  }

  /****
   * Global modificators
   */

  /* Textarea */
  &--textarea {
    resize: vertical;
    min-height: 40px;

    &:not([rows]) {
      height: 160px;
    }
  }

  &--open {
    box-shadow: 0 -2px 0 2px $input-focus-border-color inset,
    0 4px 16px rgba(69, 69, 69, 0.15);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &--textarea {
      border: 1px solid transparent;
    }
  }

  /* Disabled */
  &--disabled,
  &--readonly {
    background: #f6f6f6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALElEQVQYV2NkwALevn37nxFdHCQoLCzMiCIBEwQphksgC8Il0AXBEtgEQRIA6XcXzRBgFUoAAAAASUVORK5CYII=) repeat;
    color: $input-disabled-color;
    pointer-events: none;
  }

  &--disabled-text,
  &--readonly-text {
    color: $input-disabled-color;
  }

  /* Fluid */
  &--fluid {
    width: 100%;
  }

  &--center {
    text-align: center;
  }

  &--long-placeholder {
    &::placeholder {
      font-size: var(--font-size-sm);
    }
  }

  &--rtl {
    text-align: right;
    direction: rtl;
    unicode-bidi: bidi-override;
  }

  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched,
  &--error {
    position: relative;
    z-index: 2;
    box-shadow: 0 0 0 1px $accent-color inset,
    0 3px 7px rgba(136, 139, 141, 0.20) inset;
  }

  &--valid {
    position: relative;
    z-index: 2;
    box-shadow: 0 0 0 1px $success-color inset,
    0 3px 7px rgba(113, 183, 144, 0.20) inset;
  }

  &__tooltip-icon {
    display: inline-block;
    margin-top: 10px;
    color: $primary-color;
    font-size: var(--font-size-lg);
  }
}
