.list {
  $this: & !default;
  line-height: $base-line-height;

  & & {
    margin-left: 1.5em;
  }

  &__item {
    list-style: none;

    #{$this}:not(#{$this}--ordered) &:before {
      content: $icon-arrow;
      @include font-setup();
      margin-right: 1em;
      color: var(--list-item-color);
      font-size: 8.5px;
      vertical-align: middle;
    }
  }

  &--ordered {
    counter-reset: ordered-list;

    #{$this}__item {
      counter-increment: ordered-list;

      &:before {
        content: counter(ordered-list) '. ';
        color: var(--list-item-color);
        font-weight: var(--font-weight-bold);
      }
    }
  }
}

.files-list {
  $this: & !default;
  overflow: hidden;

  &__item {
    position: relative;
    display: block;
    margin: 5px 0;
    padding-left: 20px;

    &:before {
      position: absolute;
      top: 0.8em;
      left: 0;
    }
  }

  &__heading {
    display: block;
    font-weight: var(--font-weight-bold);
    margin: 5px 0 0;
  }

  &__link {
    color: $base-color;
    text-decoration: none;

    &:hover {
      color: $accent-color;
    }
  }

  &__link:before {
    display: inline-block;
    margin-right: 5px;
  }

  &__link--flex {
    display: inline-flex;

    #{$this}__name {
      padding-right: 30px;
    }
  }

  &__novalue {
    display: block;
    margin: 5px 0;
  }

  &__detail {
    display: block;
    font-size: 13px;
  }

  &__detail-value {
    white-space: nowrap;
  }
}

.images-list {
  $this: & !default;

  &__list {
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 25%;
    margin-top: 8px;
    padding: 0 4px;
    vertical-align: top;
  }

  &__heading {
    font-weight: var(--font-weight-bold);
    margin: 0 -20px 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid lighten($gray-color, 15%);
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 5px;
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #ccc;
    border-radius: $border-radius;
    cursor: pointer;
    transform: translate(-50%, 0);
  }

  &__detail {
    display: block;
    font-size: 13px;
  }

  &__detail-value {
    white-space: nowrap;
  }

  &--extended & {
    &__item {
      display: block;
      width: 100%;
      margin-top: 15px;
      padding: 0;
    }

    &__heading {
      display: block;
      margin: 0;
      padding-bottom: 5px;
      border: none;
    }

    &__item-content {
      display: flex;
      width: 100%;
    }

    &__link {
      display: block;
      flex: 1 0 auto;
      height: auto;
      margin: 0;
      padding: 0;
      max-width: 160px;
    }

    &__image {
      position: static;
      transform: none;
      width: 100%;
      max-height: unset;
    }

    &__meta {
      flex: 1 0 auto;
      font-size: var(--font-size-sm);
      padding-left: 15px;
    }
  }
}

.videos-list {
  $this: & !default;
  overflow: hidden;

  &__item {
    display: block;
    width: 100%;
    height: auto;
    margin: 25px 0;
    padding: 0 20px;
  }

  &__heading {
    font-weight: var(--font-weight-bold);
    margin: 0 -20px 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid lighten($gray-color, 15%);
  }

  &__no-support {
    padding: 0 0 10px;
    font-size: var(--font-size-sm);
    color: $gray-color;
  }

  &__bold {
    font-weight: var(--font-weight-bold);
    word-break: break-all;
  }

  &__detail {
    display: block;
    font-size: 13px;
  }

  &__detail-value {
    white-space: nowrap;
  }

  &__link {
    display: block;
    flex: 1 0 auto;
    max-width: 160px;
  }

  &__meta {
    flex: 1 0 auto;
    margin: 15px 0;
    font-size: var(--font-size-sm);
  }
}
