.product-header {
  &--flex {
    display: flex;
  }

  &__container {
    @include container();
    margin-bottom: 20px;
    padding-top: 20px;

    &--buttons {
      padding-left: 0;
    }
  }

  &__header {
    margin: 0;

    &--separate {
      padding-bottom: 25px;
      border-bottom: 1px solid #d0d0d0;
    }
  }

  &__category {
    margin-top: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d0d0d0;
  }

  &__category-label {
    font-weight: var(--font-weight-bold);
  }

  &__buttons {
    $width: 265px;
    $padding: 16px;
    flex: 0 0 ($width + $padding);
    flex-shrink: 0;
    width: ($width + $padding);
    justify-self: flex-end;
  }

  &__description {
    font-size: var(--font-size-xs);

    strong {
      font-weight: var(--font-weight-bold);
      font-size: 21px;
    }
  }
}
