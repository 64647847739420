.link-icon {
  $this: & !default;
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;

  &:before {
    display: block;
    width: 41px;
    height: 41px;
    line-height: 41px !important;
    margin-right: 10px;
    background: $primary-color;
    border-radius: $border-radius;
    color: #fff;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    text-align: center;
    box-shadow: 0 0 0 1px transparent inset,
    0 4px 16px rgba(0, 40, 108, 0.25);
    transition: background-color 0.250s ease,
    box-shadow 0.250s ease;
  }

  &__text {
    display: block;
    border-bottom: 1px solid;
    color: $primary-color;
    font-family: $base-font-family;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
    white-space: normal;
    transition: border-color 0.3s ease;
  }

  &:hover:before {
    box-shadow: 0 0 0 1px transparent inset,
    0 4px 16px rgba(0, 40, 108, 0.25);
  }

  &:hover &__text {
    border-color: transparent;
  }

  &--right:before {
    margin: 0 0 0 10px;
    order: 2;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @mixin changeColor($modificator, $color) {
    &--#{$modificator} &__text {
      color: $color;
    }

    &--#{$modificator}:before {
      background-color: $color;
      box-shadow: 0 0 0 1px transparent inset,
      0 4px 16px rgba($color, 0.25);
    }

    &--#{$modificator}:hover:before {
      background-color: lighten($color, 5%);
      box-shadow: 0 0 0 1px transparent inset,
      0 4px 16px rgba($color, 0.45);
    }
  }

  @include changeColor('secondary', $secondary-color);
  @include changeColor('alert', $alert-color);
  @include changeColor('error', $error-color);

}
