.btn {
  $this: & !default;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  min-height: $btn-normal-min-height;
  min-width: var(--button-min-width);
  margin: 0;
  padding: var(--button-normal-padding);
  outline: 0;
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: var(--button-box-shadow);
  background-color: $btn-default-bg-color;
  color: var(--primary-color);
  font-family: var(--button-font-family);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--button-font-weight);
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.250s ease, background-color 0.250s ease, box-shadow 0.250s ease, color 0.250s ease, background 0.250s ease;

  &:not(.btn--loading):hover,
  &:not(.btn--loading):focus,
  &:not(.btn--loading):active {
    box-shadow: var(--button-hover-box-shadow);
  }

  /*********
    Colors
  **********/

  &--flat {
    box-shadow: 0 0 0 1px $primary-color inset;
  }

  &--inverted {
    border-color: var(--button-inverted-border-color);
    background-color: var(--primary-color);
    color: #fff;
  }

  /* Primary */
  &--primary {
    box-shadow: var(--button-primary-box-shadow);
    background-color: var(--primary-color);
    color: $btn-primary-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: var(--button-primary-hover-box-shadow);
      background-color: var(--button-primary-hover-background-color);
    }
  }

  &--inverted#{$this}--primary {
    box-shadow: var(--button-primary-inverted-box-shadow);
    color: var(--button-primary-inverted-color);
    background-color: var(--button-primary-inverted-background-color);

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: var(--button-primary-inverted-hover-box-shadow);
      background-color: var(--button-primary-inverted-hover-background-color);
    }
  }

  /* Secondary */
  &--secondary {
    box-shadow: var(--button-secondary-box-shadow);
    background-color: var(--secondary-color);
    color: $btn-secondary-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: var(--button-secondary-hover-box-shadow);
      background-color: var(--button-secondary-hover-background-color);
    }
  }

  &--inverted#{$this}--secondary {
    background-color: transparent;
    box-shadow: var(--button-secondary-inverted-box-shadow);
    color: var(--button-secondary-inverted-color);

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: var(--button-secondary-inverted-hover-box-shadow);
      background-color: transparent;
    }
  }

  /* Accent */
  &--accent {
    box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.25);
    background-color: $btn-accent-bg-color;
    color: $btn-accent-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(0, 40, 108, 0.35);
      background-color: lighten($btn-accent-bg-color, 5%);
    }
  }

  &--inverted#{$this}--accent {
    background-color: transparent;
    box-shadow: 0 0 0 1px $btn-accent-bg-color inset, 0 4px 16px rgba(0, 40, 108, 0.25);
    color: $btn-accent-bg-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: 0 0 0 1px lighten($btn-accent-bg-color, 5%) inset, 0 4px 16px rgba(0, 40, 108, 0.35);
      background-color: transparent;
    }
  }

  /* Alert */
  &--alert {
    box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(241, 63, 63, 0.35);
    background-color: $btn-alert-bg-color;
    color: $btn-alert-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(241, 63, 63, 0.45);
      background-color: lighten($btn-alert-bg-color, 5%);
    }
  }

  &--inverted#{$this}--alert {
    background-color: transparent;
    box-shadow: 0 0 0 1px $btn-alert-bg-color inset, 0 4px 16px rgba(241, 63, 63, 0.35);
    color: $btn-alert-bg-color;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: 0 0 0 1px lighten($btn-alert-bg-color, 5%) inset, 0 4px 16px rgba(241, 63, 63, 0.45);
      background-color: transparent;
    }
  }

  &--info {
    box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(0, 183, 224, 0.35);
    background-color: $info-color;
    color: #fff;

    &:not(.btn--loading):hover,
    &:not(.btn--loading):focus,
    &:not(.btn--loading):active {
      box-shadow: 0 0 0 1px transparent inset, 0 4px 16px rgba(0, 183, 224, 0.45);
      background-color: lighten($info-color, 5%);
    }
  }

  &--transparent {
    background: transparent;
  }

  &--transparent#{$this}--info {
    box-shadow: 0 0 0 1px $info-color inset, 0 4px 16px rgba(0, 40, 108, 0.25);
    color: $info-color;

    &:hover {
      color: #fff;
    }
  }

  &--transparent#{$this}--alert {
    box-shadow: 0 0 0 1px $btn-alert-bg-color inset, 0 4px 16px rgba(0, 40, 108, 0.25);
    color: $btn-alert-bg-color;

    &:hover {
      color: $btn-alert-color;
    }
  }

  /*********
    Sizes
  **********/

  /* Small */
  &--small {
    min-height: $btn-small-min-height;
    padding: $btn-small-padding;
    font-size: var(--font-size-sm);
  }

  /* Smaller */
  &--smaller {
    min-height: $btn-smaller-min-height;
    padding: $btn-smaller-padding;
    font-size: var(--font-size-xs);
    line-height: 1rem;
  }

  /* Fluid */
  &--fluid {
    display: block;
    width: 100%;
  }

  &--inline-space {
    margin-left: 15px;
  }

  &--vert-space {
    margin: 0.7em 0 0.4em;
  }

  /*********
    Icons
  **********/
  &--icon-left:before,
  &--icon-right:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &--icon-left {
    padding-left: 40px;

    &:before {
      left: 13px;
    }
  }

  &--icon-right {
    padding-right: 40px;

    &:before {
      right: 13px;
    }
  }

  &--icon-fat:before {
    font-weight: var(--font-weight-bold);
  }

  &--icon-rotate-90:before {
    transform: translateY(-50%) rotate(90deg);
  }

  &--icon-rotate-180:before {
    transform: translateY(-50%) rotate(180deg);
  }

  &--icon-rotate-270:before {
    transform: translateY(-50%) rotate(270deg);
  }

  /*************
    Typography
  **************/

  &--uppercase {
    text-transform: uppercase;
  }

  &--lowercase {
    text-transform: lowercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  /**********************
    Global modificators
  ***********************/

  /* Disabled */
  &--disabled,
  &:disabled {
    opacity: 0.45;
    cursor: default;
    pointer-events: none;
  }

  /* Truncate */
  &--truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* Loading */
  &--loading {
    position: relative;
    color: transparent !important;
    cursor: default;
    pointer-events: none;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 14px;
      height: 14px;
      margin: -7px 0 0 -7px;
      border-radius: 100%;
      border-top: 2px solid rgba(0, 0, 0, 0.15);
      border-right: 2px solid rgba(0, 0, 0, 0.15);
      border-bottom: 2px solid rgba(0, 0, 0, 0.15);
      border-left: 2px solid $primary-color;
      animation: loader .6s linear;
      animation-iteration-count: infinite;
    }

    &#{$this}--primary,
    &#{$this}--secondary,
    &#{$this}--accent,
    &#{$this}--alert {
      &:before {
        border-top: 2px solid rgba(255, 255, 255, 0.15);
        border-right: 2px solid rgba(255, 255, 255, 0.15);
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
        border-left: 2px solid #fff;
      }
    }

    &#{$this}--inverted {
      &#{$this}--primary {
        &:before {
          border-left-color: $btn-primary-bg-color;
        }
      }

      &#{$this}--secondary {
        &:before {
          border-left-color: $btn-secondary-bg-color;
        }
      }

      &#{$this}--accent {
        &:before {
          border-left-color: $btn-accent-bg-color;
        }
      }

      &#{$this}--alert {
        &:before {
          border-left-color: $btn-alert-bg-color;
        }
      }
    }

    &#{$this}--small:before {
      width: 12px;
      height: 12px;
      margin: -6px 0 0 -6px;
    }

    &#{$this}--smaller:before {
      width: 10px;
      height: 10px;
      margin: -5px 0 0 -5px;
    }
  }

  &--margin-right {
    margin-right: 40px;
  }
}

.btn-wrapper {
  padding: 20px 0;

  &--flex {
    display: flex;
    padding-top: 0;
  }

  &--centered {
    display: flex;
    justify-content: center;
  }
}

.icon-btn {
  $this: & !default;
  display: inline-block;
  vertical-align: middle;
  min-height: $btn-normal-min-height;
  background: none;
  padding: 5px;
  outline: 0;
  border: none;
  color: $base-color;
  font-family: $btn-font-family;
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.250s ease, color 0.250s ease;

  &:not(#{$this}--loading):hover,
  &:not(#{$this}--loading):focus,
  &:not(#{$this}--loading):active {
    color: $primary-color;
  }

  &--alert {
    color: $error-color;

    &:not(#{$this}--loading):hover,
    &:not(#{$this}--loading):focus,
    &:not(#{$this}--loading):active {
      color: lighten($error-color, 25%);
    }
  }

  &.icon-drag {
    cursor: move;
    cursor: -webkit-grab;
    font-size: 32px;

    &:not(#{$this}--loading):hover,
    &:not(#{$this}--loading):focus,
    &:not(#{$this}--loading):active {
      color: $base-color;
    }
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &--small {
    font-size: var(--base-font-size);
  }
}

.sorting-btn {
  position: relative;
  display: inline-flex;
  min-height: 40px;
  border: none;
  margin: 0 5px;
  padding-top: 4px;
  padding-left: 20px;
  background: none;
  color: #af96d4;
  font-size: 21px;
  font-weight: var(--font-weight-bold);
  vertical-align: middle;
  cursor: pointer;

  &:before,
  &:after {
    content: 'A';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-100%);
    font-size: 90%;
    line-height: 1;
  }

  &:after {
    content: 'Z';
    transform: translateY(0%);
  }

  &__text {
    display: none;
  }

  &--desc:before {
    transform: translateY(0%);
  }

  &--desc:after {
    transform: translateY(-100%);
  }
}

.btn-info {
  margin: 0 10px;
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  line-height: 32px;
  vertical-align: middle;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
