.header {
  $this: & !default;
  margin: 1em 0;
  color: var(--header-color);
  font-family: var(--header-font-family);
  font-weight: 600;
  line-height: $base-line-height;
  text-transform: capitalize;

  @for $i from 1 through 6 {
    &--size-#{$i} {
      font-size: nth($header-font-sizes, $i);
    }
  }

  &__subheader {
    font-size: var(--font-size-lg);
  }

  &__no-text-transform {
    text-transform: none;
  }

  &__no-margin {
    margin: 0;
  }

  &__no-top-margin {
    margin-top: 0;
  }
}
