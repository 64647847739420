.api-doc {
  $this: & !default;

  &__table_separator {
    height: 50px;
    display: block;
  }

  &__one-line-code {
    text-align: center;
    font-family: monospace;
    margin-bottom: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 40px 0;

    &--flex-column {
      flex-direction: column;
    }

    &--no-top-padding {
      padding-top: 0;
    }
  }

  &__header-title {
    margin: 0;
    line-height: 1.4;
  }

  &__header-meta {
    display: block;
    font-size: var(--font-size-lg);
  }

  &__list {
    list-style-type: none;
    padding-left: 30px;

    &--more-readable {
      line-height: 150%;
    }

    & > li {
      &:before {
        content: '-';
        margin-right: 5px;
      }
    }

    & > li > ul {
      list-style-type: none;
      padding-left: 15px;

      & > li {
        &:before {
          content: '-';
          margin-right: 5px;
        }
      }
    }

    &.response-desc {
      li {
        line-height: 150%;
        margin-top: 10px;
        font-weight: var(--font-weight-bold);

        .api-doc__list {
          padding-left: 45px;

          li {
            font-weight: normal;
            margin-top: 0;
          }
        }
      }
    }
  }

  &__button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  //&__footer {
  //    display: flex;
  //    align-items: center;
  //    padding: 64px 0;
  //    border-top: 2px solid $primary-color;
  //
  //    &--modal {
  //        padding: 32px 0 8px;
  //    }
  //
  //    &--sticky,
  //    &--alt-sticky {
  //        z-index: 10;
  //        bottom: 0;
  //        left: 0;
  //        width: 100%;
  //        height: 140px;
  //        background: #fff;
  //        padding: 32px 16px;
  //    }
  //
  //    &--alt-sticky {
  //        position: sticky;
  //        margin-top: 20px;
  //    }
  //
  //    &-info {
  //        overflow: hidden;
  //
  //        &-container {
  //            overflow: hidden;
  //            display: flex;
  //            max-width: 400px;
  //            align-items: center;
  //
  //            figure {
  //                display: block;
  //                margin-right: 16px;
  //            }
  //        }
  //    }
  //
  //    &-image {
  //        max-width: 50px;
  //
  //        &--placeholder {
  //            background-color: $light-gray-color;
  //        }
  //    }
  //}
  //
  //&__actions {
  //    display: flex;
  //    margin-bottom: 32px;
  //    padding: 10px 15px;
  //    background-color: #fbfbfb;
  //    border-radius: 0 0 $border-radius $border-radius;
  //
  //    a, button {
  //        margin-left: auto;
  //    }
  //
  //    &--no-margin {
  //        margin: 0;
  //    }
  //}

  &__flex {
    flex: 1;
  }

  &__button {
    flex-shrink: 0;
    padding-left: 20px;
  }

  &__section {
    display: block;
    margin-bottom: 64px;

    &--margin-bottom-32 {
      margin-bottom: 32px;
    }

    &--align-center {
      text-align: center;
    }

    &--wrapper {
      margin: 0 20px;
    }

    &--margin-top-32 {
      margin-top: 32px;
    }

    &.general-information {
      margin-bottom: 45px;

      h4 {
        margin-top: 20px;
      }

      p {
        line-height: 1.5;
      }
    }
  }

  //&__section-row {
  //    display: flex;
  //    align-items: center;
  //
  //    & > * {
  //        margin-right: 5px !important;
  //        display: block;
  //    }
  //}
  //
  &__section-title {
    margin-bottom: 32px;

    &--no-margin {
      margin: 0;
    }

    &--margin-top-32 {
      margin-top: 32px;
    }
  }

  //
  //&__section-tooltip {
  //    color: $primary-color;
  //    cursor: pointer;
  //}
  //
  //&__single-input {
  //    padding: 8px 10px;
  //}

  &__row {
    display: flex;

    &:not(&--inline):not(&--flex) {
      @include row();
    }

    &--inline {
      #{$this}__group {
        display: inline-block;
        vertical-align: top;
        margin: 0 !important;
      }
    }

    &--flex {
      display: flex;
      justify-content: space-between;

      #{$this}__group {
        margin: 0 !important;
      }
    }

    &--flex-multirow {
      display: flex;
      flex-wrap: wrap;
    }

    &--no-margin {
      margin-bottom: 0 !important;
    }
  }

  &__group {
    @include column-classes('--cols-');
  }

  // remove this above }
  //    &--grow {
  //        flex-grow: 1;
  //
  //        &:not(:last-of-type) {
  //            padding-right: 16px;
  //        }
  //    }
  //
  //    &--align-center {
  //        align-self: center;
  //    }
  //
  //    &--content-middle {
  //        display: flex;
  //        justify-content: center;
  //        align-items: center;
  //        align-content: center;
  //    }
  //
  //    &--text-right {
  //        text-align: right;
  //    }
  //
  //    &--margin {
  //        margin-bottom: 32px;
  //    }
  //
  //    &--small-margin {
  //        margin-bottom: 16px;
  //    }
  //
  //    &--transition-bg {
  //        &:before {
  //            transition: background-color ease .3s;
  //        }
  //    }
  //
  //    &--transition-bg,
  //    &--mandatory,
  //    &--optional {
  //        position: relative;
  //
  //        &:before {
  //            content: '';
  //            box-sizing: content-box;
  //            z-index: -1;
  //            position: absolute;
  //            top: -16px;
  //            left: 0;
  //            width: 100%;
  //            height: 100%;
  //            padding: 16px 0;
  //            border-radius: $border-radius;
  //        }
  //    }
  //
  //    &--mandatory {
  //        &:before {
  //            background-color: lighten($alert-color, 32%);
  //        }
  //    }
  //
  //    &--optional {
  //        &:before {
  //            background-color: lighten($primary-color, 25%);
  //        }
  //    }
  //}

  &__group:not(:last-of-type):not([class*='cols']),
  &__row:not(:last-of-type) {
    margin-bottom: 32px;
  }

  &__group-row {
    position: relative;
    display: flex;
    margin: 2px 0;
    padding: 2px 5px;
    background: lighten($primary-color, 25%);

    &--required {
      background: lighten($alert-color, 32%);
    }

    &--optional {
      background: transparent;
    }

    &--icon {
      position: absolute;
      top: 10px;
      left: -30px;
      font-size: 22px;
    }
  }

  &__group-text {
    flex-shrink: 0;
    align-self: center;
    font-size: var(--font-size-xs);

    &--error {
      color: $error-color;
    }
  }

  //&__expandable-content {
  //    padding-left: 32px;
  //
  //    &--hidden {
  //        overflow: hidden;
  //    }
  //
  //    &--hide {
  //        display: none;
  //    }
  //}
  //
  //&__instruction {
  //    margin-bottom: 16px;
  //}
  //
  //&__label {
  //    margin-bottom: 16px;
  //
  //    &--block {
  //        display: block;
  //    }
  //}
  //
  //&__microcopy {
  //    margin-top: 8px;
  //}
  //
  //&__separator {
  //    position: relative;
  //    margin: 0;
  //    border: none;
  //
  //    &:before {
  //        content: '';
  //        position: absolute;
  //        left: 50%;
  //        transform: translateX(-50%);
  //        width: 50%;
  //        border-top: 1px solid #ccc;
  //    }
  //}
  //
  //&__asterisk-info {
  //    margin-top: 12px;
  //    font-size: 12px;
  //    color: $dark-gray-color;
  //}
  //
  //body.medxsync-theme & {
  //    &__footer {
  //        border-top: 2px solid $medxsync-primary-color;
  //    }
  //}
  //
  //body.municipality-theme & {
  //    &__footer {
  //        border-top: 2px solid $municipality-primary-color;
  //    }
  //}
  //
  //body.fta-theme & {
  //    &__footer {
  //        border-top: 2px solid $fta-primary-color;
  //    }
  //}
}

.pdf-generate-source {
  position: fixed;
  bottom: 100%;
}
