.color-picker {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  box-shadow: 0 4px 16px var(--colorpicker-box-shadow-color);
  border: 2px solid var(--colorpicker-border-color) !important;
  border-radius: $border-radius;

  input {
    box-shadow: 0 0 0 1px var(--colorpicker-input-box-shadow-color) inset !important;
    border: none !important;
    border-radius: $border-radius !important;
  }

  .cursor {
    border: 2px solid var(--colorpicker-border-color) !important;
  }

  .selected-color {
    border: 1px solid var(--colorpicker-border-color) !important;
  }
}
