.table-toolbar {
  margin: 20px 0;

  &--margin-30 {
    margin: 30px 0;
  }

  &__row {
    display: flex;
    margin: 20px 0;
  }

  &__pagination {
    flex: 1 1 auto;
  }

  &__bulk-actions {
    flex: 0 0 381px;
    margin-left: 32px;
  }

  &--space-between &__row {
    justify-content: space-between;
  }
}
