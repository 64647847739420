.errors {
  $this: & !default;
  padding-left: 20px;
  list-style: disc;

  &__single-error {
    display: flex;
    margin: 0;
    color: $error-color;
    font-size: var(--font-size-sm);
    line-height: 1.1em;
    overflow: hidden;
    line-height: 1.1;
  }

  &__field {
    width: 150px;
    font-weight: var(--font-weight-bold);
  }

  &__suberrors {
    padding-left: 15px;
  }

  &__suberrors-item {
    display: block;
  }

  &__suberrors-item + &__suberrors-item {
    margin-top: 8px;
  }

  &__suberrors--display-pre {
    #{$this}__suberrors-item {
      white-space: pre-line;
    }
  }

  &--warning {
    #{$this}__single-error {
      color: $alert-color;
    }

    &#{$this}--block {
      background-color: $alert-color;
    }
  }

  &--block {
    margin-bottom: 32px;
    padding: 8px 16px;
    background-color: $error-color;
    border-radius: $border-radius;

    #{$this}__single-error {
      color: #fff;
    }

    #{$this}__single-error + #{$this}__single-error {
      margin-top: 8px;
    }
  }

  &--margin-top {
    margin: 10px 0 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--inside {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 0;

    #{$this}__single-error {
      padding: 7px 12px;
      color: #fff;
      background-color: $error-color;
      font-size: var(--font-size-xs);
      text-align: left;

      &:first-child {
        &:before {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;
          height: 10px;
          background: $error-color;
        }
      }

      &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }

  &--info {
    #{$this}__single-error {
      background-color: $info-color;

      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }

  &--clear-margin {
    margin: 0;
  }

  &--margin-top-8 {
    margin-top: 8px;
  }
}
