.breadcrumbs {
  background-color: #f4f4f4;
  font-size: var(--base-font-size);

  &__container {
    @include container();
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: space-between;
  }

  &__link {
    padding: 0;
    background: transparent;
    border: none;
    color: $dark-gray-color;
    font-family: $base-font-family;
    font-size: var(--base-font-size);
    line-height: 16px;
    text-transform: capitalize;
    text-decoration: underline;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }

    &--disabled {
      cursor: default;
      opacity: .3;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button {
      padding: 0 10px;
    }
  }
}
