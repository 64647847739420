.table {
  $this: & !default;
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 80px;
  border-radius: $border-radius;

  &__wrapper {
    overflow: auto;
    max-height: 80vh;
  }

  &__row {
    display: table-row;
    outline: none;

    &:nth-child(2n+2) {
      #{$this}__cell {
        background-color: $cell-background-odd;
      }
    }

    &:nth-child(2n+1) {
      #{$this}__cell {
        background-color: $cell-background-even;
      }
    }

    &--transparent {
      #{$this}__cell {
        background-color: transparent !important;
      }
    }

    &--warning {
      #{$this}__cell:not(.table__cell--nodata) {
        background: #f4e6e6;
      }
    }

    &--clickable {
      cursor: pointer;
    }

    &--new {
      #{$this}__cell {
        animation: 6s ease-in-out;
        @include animation {
          from {
            background-color: var(--table-animation-color);
          }
        }
      }
    }

    &--radio {
      padding-bottom: 10px;
    }

    &--more-readable {
      line-height: 150%;
    }
  }

  &--inverse-row-colors &__row {
    &:nth-child(2n+1) {
      #{$this}__cell:not(th) {
        background-color: $cell-background-even;
      }
    }

    &:nth-child(2n+2) {
      #{$this}__cell:not(th) {
        background-color: $cell-background-odd;
      }
    }
  }

  &__filter-group {
    display: table-header-group;
  }

  &__filter-row &__cell {
    border-bottom: 1px solid #b1b3b3;
  }

  &__date-range {
    display: flex;
    align-items: center;

    my-date-picker {
      flex: 1 1 100%;
    }
  }

  &__spacer {
    display: block;
    padding: 0 8px;
  }

  &__cell-header {
    display: table-cell;
    vertical-align: middle;
    height: $header-cell-height;
    padding: 7px 15px;
    background: var(--primary-color);
    color: $header-cell-color;
    font-family: var(--header-font-family);
    text-align: left;
    text-transform: capitalize;
    word-break: break-word;
    word-wrap: break-word;

    &:first-child,
    &--zero-width:first-child + & {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }

    &--subheader {
      height: calc(#{$header-cell-height} * 0.6);
      background: lighten($header-cell-background, 10%);
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    height: $cell-height;
    padding: 7px 15px;
    border: none;
    color: $cell-color;
    text-align: left;
    word-break: break-word;
    word-wrap: break-word;

    &--nodata {
      background-color: $cell-background-no-data !important;
      border-radius: 0 0 $border-radius $border-radius;
      text-align: center;
    }

    &--flex {
      display: flex;
    }

    &--stop-propagation {
      cursor: default;
    }

    &--radio {
      padding: 0 10px;
      text-align: center;
      vertical-align: middle;
    }

    &--radio-header {
      padding: 10px;
    }

    &--label {
      vertical-align: middle;
    }

    #{$this}__status {
      z-index: 6;
      position: absolute;
      top: calc(50% - 13px); // calc = 50% minus height minus margin
      left: 0;
      margin-left: 0;
      transition: opacity 0.3s ease 0.3s;
      transform: translateX(-50%);

      &--show {
        opacity: 1;
      }
    }

    &--color-negative {
      color: $error-color;
    }

    &--color-positive {
      color: $success-color;
    }

    &--sticky {
      z-index: 7;
      position: sticky;
      left: 0;
    }

    strong {
      font-weight: var(--font-weight-bold);
    }
  }

  &__cell-shadow:before {
    position: absolute;
    content: '';
    top: 0;
    left: 100%;
    width: 10px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 90%);
  }

  span#{&}__cell-loader {
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;

    $size: 22px;

    width: $size + 2;
    height: $size + 2;

    &:before {
      width: $size;
      height: $size;
      margin-top: calc($size / -2);
      margin-left: calc($size / -2);
    }
  }

  &__cell--loading {
    position: relative;
  }

  &__cell--loading span#{&}__cell-loader {
    opacity: 1;
  }

  &__cell-input + &__cell-input {
    margin-top: 7px;
  }

  &__tooltip-container {
    position: relative;
    display: inline;
  }

  &__tooltip-icon {
    position: absolute;
    top: 0;
    right: -40px;
    min-height: auto;
    padding: 0;
    color: $header-cell-background;
    cursor: default;

    &--inline {
      position: static;
      padding: 4px 4px 4px 0;
    }
  }

  &__status-container {
    display: flex;
  }

  &__img-container {
    position: relative;
    display: block;
  }

  &__img {
    overflow: hidden;
    width: 100%;
    min-height: 68px;
    border: 1px solid #ccc;
    border-radius: $border-radius;
    background-color: #fff;

    &--empty {
      padding-top: calc(100% - 2px);
      background-image: linear-gradient(to top left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(136, 139, 141, 0.20) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%),
      linear-gradient(to top right,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgba(136, 139, 141, 0.20) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%);
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    &--no-border {
      border: none;
    }

    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__cell-wrapper--flex {
    display: flex;
    margin: 0 -4px;
  }

  &__cell-wrapper--flex &__cell-input {
    width: 100%;
    flex-grow: 1;
    margin: 0;
    padding: 0 4px;
  }

  &__loading {
    position: absolute;
    top: 0;
    bottom: -1px;
    opacity: 0;
    z-index: 5;
    color: transparent !important;
    width: 100%;
    min-height: 40px;
    background: rgba(255, 255, 255, 0.85);
    cursor: default;
    pointer-events: none;
    user-select: none;
    will-change: opacity;
    transition: opacity 0.3s ease;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin: -16px 0 0 -16px;
      border-radius: 100%;
      border-top: 2px solid rgba(160, 160, 160, 0.15);
      border-right: 2px solid rgba(160, 160, 160, 0.15);
      border-bottom: 2px solid rgba(160, 160, 160, 0.15);
      border-left: 2px solid #6b6b6b;
      animation: loader .6s linear;
      animation-iteration-count: infinite;
    }

    &--show {
      opacity: 1;
    }
  }

  &__pointer {
    position: absolute;
    top: 50%;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $table-pointer-color;
    transform: translateY(-50%);
  }

  &--center {
    > #{$this}__row > #{$this}__cell,
    > thead > #{$this}__row > #{$this}__cell-header {
      text-align: center;
    }
  }

  &--right {
    > #{$this}__row > #{$this}__cell,
    > thead > #{$this}__row > #{$this}__cell-header {
      text-align: right;
    }
  }

  &__cell,
  &__cell-header {
    #{$this} &--left {
      text-align: left;
    }

    #{$this} &--center {
      text-align: center;
    }

    #{$this} &--right {
      text-align: right;
    }

    #{$this} &--justify {
      text-align: justify;
    }
  }

  &__cell-title {
    font-weight: var(--font-weight-bold);

    &--sortable {
      position: relative;
      display: inline-block;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: -15px;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        opacity: 0.4;
      }

      &:after {
        transform: translateY(-130%);
        border-bottom: 5px solid #fff;
      }

      &:before {
        transform: translateY(30%);
        border-top: 5px solid #fff;
      }
    }

    &--ascending {
      &:after {
        opacity: 1;
      }

      &:before {
        opacity: 0.2;
      }
    }

    &--descending {
      &:after {
        opacity: 0.2;
      }

      &:before {
        opacity: 1;
      }
    }
  }

  &__tooltip-marker {
    display: inline-block;
    margin-top: 10px;
  }

  &--border:not(&--no-bottom-border) {
    border-bottom: 1px solid $gray-color;
  }

  &__top-separator {
    margin-top: $cell-height;
  }

  &__warning {
    margin-top: $cell-height;
    margin-bottom: $cell-height;

    th.table__cell-header.warning {
      background: red;
    }
  }

  &--border {
    > #{$this}__row > #{$this}__cell:not(#{$this}__cell--zero-width),
    > thead > #{$this}__row > #{$this}__cell-header:not(#{$this}__cell-header--zero-width) {
      border-right: 1px solid $gray-color;

      &:last-child {
        border-right: none;
      }
    }

    > thead > #{$this}__row > #{$this}__cell-header--sticky-left,
    > #{$this}__row > #{$this}__cell--sticky {
      box-shadow: 1px 0 0 0 $gray-color;
    }
  }

  // Ellipsis for whole table
  &--ellipsis {
    > #{$this}__row > #{$this}__cell,
    > thead > #{$this}__row > #{$this}__cell-header {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: initial;
      word-wrap: initial;
    }
  }

  // Ellipsis for single column
  &__cell-header--ellipsis,
  &__cell--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: initial;
    word-wrap: initial;
  }

  // Used when table has set ellipsis but you want to clear it for single column
  &__cell-header--clear-ellipsis,
  &__cell--clear-ellipsis {
    overflow: initial !important;
    text-overflow: initial !important;
    white-space: initial !important;
  }

  // Clear padding for single cell
  &__cell-header--no-padding,
  &__cell--no-padding {
    padding: 0;
  }

  &--hover {
    > #{$this}__row:hover > #{$this}__cell:not(.table__cell--nodata) {
      background: var(--table-hover-color);
    }
  }

  &--small-text {
    > thead > #{$this}__row > #{$this}__cell-header {
      font-weight: 400;
      font-size: var(--font-size-sm);
    }
  }

  &--small {
    font-size: var(--font-size-sm);
    height: auto;

    #{$this}__cell {
      height: 30px;
      padding: 5px 8px;
    }
  }

  &--loading {
    padding-bottom: 40px;
  }

  &--no-height {
    height: 0;
  }

  &--full-height {
    height: 100%; // Works only for webkit browser (doesn't work for Firefox)
  }

  &__cell-header--sortable {
    cursor: pointer;
  }

  &__cell-header--hidden {
    height: 1px;
    padding: 0;
    border: 0;
    visibility: hidden;
  }

  &__cell-container {
    &--right {
      display: flex;
      justify-content: flex-end;
    }

    &--center {
      display: flex;
      justify-content: center;
    }
  }

  &__cell-header--sticky-top,
  &__cell-header--sticky-left {
    position: sticky;
  }

  &__cell-header--sticky-top {
    z-index: 8;
    top: 0;
  }

  &__cell-header--sticky-left {
    z-index: 9;
    left: 0;;
  }

  @keyframes loader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // Fix for multiple gradients in IE 11
    // Use image instead of gradient
    &__img--empty {
      background-image: url('/assets/images/img-placeholder.png');
    }
  }
}
