html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  overflow-y: scroll;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);

  &, & * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before,
    &:after {
      box-sizing: border-box;
    }
  }

  .text-link {
    color: var(--link-color);
    text-decoration: underline;
    background: transparent;
    border: none;
    font-size: var(--base-font-size);
    cursor: pointer;

    &:hover {
      color: $accent-darker-color;
    }

    &:focus {
      outline: none;
    }

    &--small {
      font-size: var(--font-size-xs);
    }
  }

  .text-bold {
    font-weight: var(--font-weight-bold);
  }

  &.medxsync-theme {

    .error-link {
      color: $error-color;
    }
  }
}

pre {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: $border-radius;
  border: 1px solid #eee;
  background: #fff;
  color: #333;
  font-family: monospace;
  font-size: var(--font-size-xs);
  line-height: 1.3;
  text-align: left;
  overflow: auto;
  white-space: pre-wrap;
}


@mixin loader {
  width: 30px;
  height: 30px;
  margin: 20px;
  border-radius: 100%;
  border-top: 2px solid rgba(160, 160, 160, 0.15);
  border-right: 2px solid rgba(160, 160, 160, 0.15);
  border-bottom: 2px solid rgba(160, 160, 160, 0.15);
  border-left: 2px solid #6b6b6b;
  animation: loader .6s linear;
  animation-iteration-count: infinite;
  box-sizing: border-box;
}

.loading {
  @include loader;
  right: 0;
}

.uae-pass-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 560px;

  .uae-pass-login-spinner {
    @include loader;
    width: 100px;
    height: 100px;
    opacity: 50%;
    border-top: 10px solid rgba(160, 160, 160, 0.15);
    border-right: 10px solid rgba(160, 160, 160, 0.15);
    border-bottom: 10px solid rgba(160, 160, 160, 0.15);
    border-left: 10px solid #6b6b6b;
  }
}
