.radio {
  $this: & !default;
  display: inline-block;
  min-width: 150px;
  padding: 7px 0;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 1px;
    height: 1px;
  }

  &__label {
    position: relative;
    display: block;
    padding: 5px 11px 5px 36px;
    will-change: color;
    transition: color 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #ccc;
      box-shadow: 0 3px 7px 0 rgba(136, 139, 141, 0.1) inset;
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 8px;
      transform: scale(0);
      width: 9px;
      height: 9px;
      background: var(--radio-color);
      border-radius: 50%;
      transition: transform 0.25s ease-in-out;
    }
  }

  &__input:checked + #{$this}__label {
    color: var(--radio-color);

    &:before {
      border: 2px solid var(--radio-color);
      box-shadow: 0 4px 16px 0 rgba(69, 69, 69, 0.1);
    }

    &:after {
      transform: scale(1);
      transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.520, 2.15);
    }
  }

  &--success {
    #{$this}__label {
      &:before {
        border-color: $success-color;
      }

      &:after {
        background: $success-color;
      }
    }

    #{$this}__input:checked + #{$this}__label {
      &:before {
        border-color: $success-color;
        box-shadow: 0 4px 16px 0 rgba($success-color, 0.1);
      }
    }
  }

  &--error {
    #{$this}__label {
      &:before {
        border-color: $error-color;
      }

      &:after {
        background: $error-color;
      }
    }

    #{$this}__input:checked + #{$this}__label {
      &:before {
        border-color: $error-color;
        box-shadow: 0 4px 16px 0 rgba($error-color, 0.1);
      }
    }
  }

  &--disabled {
    pointer-events: none;
    color: #b1b3b3;

    &:before {
    }

    &:before,
    &:after {
      border-color: #b1b3b3 !important;
    }

    #{$this}__label:before {
      background: #f6f6f6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALElEQVQYV2NkwALevn37nxFdHCQoLCzMiCIBEwQphksgC8Il0AXBEtgEQRIA6XcXzRBgFUoAAAAASUVORK5CYII=) repeat;
    }

    #{$this}__label:after {
      background: #b1b3b3;
    }

    #{$this}__input:checked + #{$this}__label {
      &:before {
        border-color: #b1b3b3;
        box-shadow: 0 4px 16px 0 rgba(#b1b3b3, 0.1);
      }
    }
  }

  &--vertical {
    display: block;
    min-width: auto;

    &-small {
      padding: 1px 0;
      text-align: left;
    }
  }

  &--without-text {
    min-width: 25px;

    #{$this}__label {
      width: 25px;
      height: 25px;
      padding: 0;
    }
  }

  &--waisted {
    min-width: auto;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--left {
    text-align: left;
  }
}
