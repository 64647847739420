.bulk-actions {
  $this: & !default;
  display: flex;
  width: 100%;
  max-width: 381px;

  &__select {
    max-width: 260px;
    flex: 1 0 0;
  }

  &__btn {
    flex-shrink: 0;
    margin-left: 4px;
  }

  &--list {
    max-width: 350px;
    padding: 0 16px;
    background: var(--primary-color);
    color: #fff;
    flex-direction: column;
    border-radius: $border-radius;
    box-shadow: 0 4px 16px 4px var(--bulk-actions-list-box-shadow-color);
  }

  &--list &__label {
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    font-family: var(--header-font-family);
    font-size: var(--font-size-sm);
    cursor: pointer;

    &:after {
      @include font-setup();
      content: $icon-chevron;
      display: block;
      margin: auto;
      margin-right: 0;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      transform: rotate(0deg);
      transition: transform 0.4s ease-in-out;
    }
  }

  &--list &__label--open:after {
    transform: rotate(-180deg);
  }

  &--list &__list-item {
    display: block;

    &:first-child #{$this}__list-link {
      border-top: 1px solid #fff;
    }

    &:last-child #{$this}__list-link {
      border: none;
    }
  }

  &--list &__list-link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 21px 0 21px 40px;
    border-bottom: 1px solid rgba(#fff, 0.25);
    background: var(--primary-color);
    font-size: var(--font-size-xs);
    font-family: var(--header-font-family);
    text-transform: uppercase;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 24px;
    }
  }
}

