.statuses {
  $this: & !default;
  overflow: hidden;
  position: relative;
  display: inline-block;
  height: 24px;
  margin: 3px 0;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid var(--statuses-border-color);
  border-radius: 24px;
  box-shadow: 0 2px 6px rgba(175, 150, 212, 0.25);
  color: var(--statuses-color);
  font-size: var(--font-size-xxs);
  font-weight: var(--statuses-font-weight);
  line-height: 22px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  &--info {
    border: 1px solid var(--statuses-info-border-color);
    box-shadow: 0 2px 6px var(--statuses-info-box-shadow-color);
    color: var(--statuses-info-color);
  }

  &--error {
    border: 1px solid $error-color;
    box-shadow: 0 2px 6px rgba(229, 15, 35, 0.25);
    color: $error-color;
  }

  &--active {
    background-color: var(--statuses-active-background-color);
    border: 1px solid var(--statuses-active-border-color);
    box-shadow: 0 2px 6px rgba(113, 183, 144, 0.25);
    color: var(--statuses-active-color);
  }

  &--disabled {
    background-color: #bbb;
    border: 1px solid #bbb;
    box-shadow: 0 2px 6px rgba(102, 102, 102, 0.25);
    color: #fff;
  }

  &--alert {
    border: 1px solid var(--statuses-alert-border-color);
    box-shadow: 0 2px 6px rgba(237, 194, 66, 0.25);
    color: var(--statuses-alert-color);
  }

  &--new {
    border: 1px solid $info-color;
    box-shadow: 0 2px 6px rgba(0, 164, 222, 0.25);
    color: $info-color;
  }

  &--updated {
    border: 1px solid $info-color;
    box-shadow: 0 2px 6px rgba(0, 164, 222, 0.25);
    color: $info-color;
  }

  &--fluid {
    width: 100%;
  }

  &--wide {
    width: 100%;
    max-width: 200px;
    text-align: center;
  }

  &--circular {
    position: relative;
    height: 26px;
    width: 26px;
    margin: 0 5px;
    border-radius: 50%;
    line-height: 16px;
    vertical-align: middle;
    flex-shrink: 0;

    &#{$this}--new,
    &#{$this}--updated {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        font-size: var(--font-size-sm);
        justify-content: center;
        align-items: center;
      }
    }


    &#{$this}--new {
      border: 1px solid var(--statuses-circular-new-border-color);
      box-shadow: 0 2px 6px rgba(113, 183, 144, 0.25);
      color: var(--statuses-circular-new-color);

      &:after {
        content: 'N';
      }
    }

    &#{$this}--frozen {
      border: 1px solid $secondary-color;
      box-shadow: 0 2px 6px rgba(113, 183, 144, 0.25);
      color: $secondary-color;

      &:after {
        position: absolute;
        top: 6px;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 40px;
        font-weight: 100;
        justify-content: center;
        align-items: center;
        content: '*';
      }
    }

    &#{$this}--updated {
      background: var(--statuses-circular-updated-background-color);
      box-shadow: 0 2px 6px var(--statuses-circular-updated-box-shadow-color);
      color: #fff;
      border: 1px solid var(--statuses-circular-updated-border-color);

      &:after {
        content: 'U';
      }
    }
  }

  &--link {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &--inline {
    margin: 0 5px;
    vertical-align: middle;
  }
}
