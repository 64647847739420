.tooltip {
  $this: & !default;
  position: absolute;
  z-index: 100;
  display: table; // Fix for text wrapping
  max-width: 400px;

  &.positioning {
    top: 0 !important;
    left: 0 !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }

  &.wide {
    max-width: 800px;
  }

  strong {
    font-weight: var(--font-weight-heavy);
  }

  p {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__inner {
    padding: 9px;
    border-radius: $border-radius;
    font-size: var(--font-size-xs);
    line-height: 1.5;
  }

  &__content {
    white-space: pre-line;
  }

  &__arrow,
  &__arrow:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  // Top & bottom positions
  &--top,
  &--top-center,
  &--top-left,
  &--top-right {
    padding-bottom: 16px;

    #{$this}__arrow {
      bottom: 6px;
      border-width: 10px 8px 0 8px;

      &:before {
        bottom: 2px;
        border-width: 10px 8px 0 8px;
        transform: translateX(-50%);
      }
    }
  }

  &--bottom,
  &--bottom-center,
  &--bottom-left,
  &--bottom-right {
    padding-top: 16px;

    #{$this}__arrow {
      top: 6px;
      border-width: 0 8px 10px 8px;

      &:before {
        top: 2px;
        border-width: 0 8px 10px 8px;
        transform: translateX(-50%);
      }
    }
  }

  // Top & bottom aligns
  &--top &__arrow,
  &--top-center &__arrow,
  &--bottom &__arrow,
  &--bottom-center &__arrow {
    left: 50%;
    transform: translateX(-50%);
  }

  &--top-left &__arrow,
  &--bottom-left &__arrow {
    left: 10px;
  }

  &--top-right &__arrow,
  &--bottom-right &__arrow {
    right: 10px;
  }

  // Left & right positions
  &--left,
  &--left-center,
  &--left-top,
  &--left-bottom {
    padding-right: 16px;

    #{$this}__arrow {
      right: 6px;
      border-width: 8px 0 8px 10px;

      &:before {
        right: 2px;
        border-width: 8px 0 8px 10px;
        transform: translateY(-50%);
      }
    }
  }

  &--right,
  &--right-center,
  &--right-top,
  &--right-bottom {
    padding-left: 16px;

    #{$this}__arrow {
      left: 6px;
      border-width: 8px 10px 8px 0;

      &:before {
        left: 2px;
        border-width: 8px 10px 8px 0;
        transform: translateY(-50%);
      }
    }
  }

  // Left & right aligns
  &--left &__arrow,
  &--left-center &__arrow,
  &--right &__arrow,
  &--right-center &__arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  &--left-top &__arrow,
  &--right-top &__arrow {
    top: 10px;
  }

  &--left-bottom &__arrow,
  &--right-bottom &__arrow {
    bottom: 10px;
  }

  /****************/
  /* Purple theme */
  /****************/
  &--purple-theme {
    #{$this}__inner {
      border: 1px solid var(--primary-color);
      background-color: var(--primary-color);
      box-shadow: 0 0 10px #fff;
      color: #fff;
    }

    // Top & bottom positions
    &#{$this}--top,
    &#{$this}--top-center,
    &#{$this}--top-left,
    &#{$this}--top-right {
      #{$this}__arrow {
        border-color: var(--primary-color) transparent transparent transparent;

        &:before {
          border-color: transparent;
        }
      }
    }

    &#{$this}--bottom,
    &#{$this}--bottom-center,
    &#{$this}--bottom-left,
    &#{$this}--bottom-right {
      #{$this}__arrow {
        border-color: transparent transparent var(--primary-color) transparent;

        &:before {
          border-color: transparent;
        }
      }
    }

    // Left & right positions
    &#{$this}--left,
    &#{$this}--left-center,
    &#{$this}--left-top,
    &#{$this}--left-bottom {
      #{$this}__arrow {
        border-color: transparent transparent transparent var(--primary-color);

        &:before {
          border-color: transparent;
        }
      }
    }

    &#{$this}--right,
    &#{$this}--right-center,
    &#{$this}--right-top,
    &#{$this}--right-bottom {
      #{$this}__arrow {
        border-color: transparent var(--primary-color) transparent transparent;

        &:before {
          border-color: transparent;
        }
      }
    }
  }

  /***************/
  /* White theme */
  /***************/
  &--white-theme {
    #{$this}__inner {
      border: 1px solid var(--primary-color);
      background-color: #fff;
      box-shadow: 0 0 10px #fff;
      color: $base-color;
    }

    // Top & bottom positions
    &#{$this}--top,
    &#{$this}--top-center,
    &#{$this}--top-left,
    &#{$this}--top-right {
      #{$this}__arrow {
        border-color: var(--primary-color) transparent transparent transparent;

        &:before {
          border-color: #fff transparent transparent transparent;
        }
      }
    }

    &#{$this}--bottom,
    &#{$this}--bottom-center,
    &#{$this}--bottom-left,
    &#{$this}--bottom-right {
      #{$this}__arrow {
        border-color: transparent transparent var(--primary-color) transparent;

        &:before {
          border-color: transparent transparent #fff transparent;
        }
      }
    }

    // Left & right positions
    &#{$this}--left,
    &#{$this}--left-center,
    &#{$this}--left-top,
    &#{$this}--left-bottom {
      #{$this}__arrow {
        border-color: transparent transparent transparent var(--primary-color);

        &:before {
          border-color: transparent transparent transparent #fff;
        }
      }
    }

    &#{$this}--right,
    &#{$this}--right-center,
    &#{$this}--right-top,
    &#{$this}--right-bottom {
      #{$this}__arrow {
        border-color: transparent var(--primary-color) transparent transparent;

        &:before {
          border-color: transparent #fff transparent transparent;
        }
      }
    }
  }
}
