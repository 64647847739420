/* icons - icomoon */
@font-face {
  font-family: 'icons';
  src: url('assets/fonts/icons.woff2?twh6u9') format('woff2'),
  url('assets/fonts/icons.ttf?twh6u9') format('truetype'),
  url('assets/fonts/icons.woff?twh6u9') format('woff'),
  url('assets/fonts/icons.svg?twh6u9#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* roboto-regular - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('assets/fonts/roboto/roboto-v15-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/roboto/roboto-v15-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('assets/fonts/roboto/roboto-v15-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/roboto/roboto-v15-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('assets/fonts/montserrat/montserrat-v10-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/montserrat/montserrat-v10-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-italic - latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src: local('Lora Italic'), local('Lora-Italic'),
  url('assets/fonts/lora/lora-v10-latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/lora/lora-v10-latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-700italic - latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  src: local('Lora Bold Italic'), local('Lora-BoldItalic'),
  url('assets/fonts/lora/lora-v10-latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/lora/lora-v10-latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-200 - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 200;
  src: local('Cairo ExtraLight'), local('Cairo-ExtraLight'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-regular - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  src: local('Cairo'), local('Cairo-Regular'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-300 - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  src: local('Cairo Light'), local('Cairo-Light'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-600 - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-700 - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  src: local('Cairo Bold'), local('Cairo-Bold'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cairo-900 - latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  src: local('Cairo Black'), local('Cairo-Black'),
  url('assets/fonts/cairo/cairo-v5-latin-ext-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/cairo/cairo-v5-latin-ext-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* GE SS Two */
@font-face {
  font-family: 'GE SS Two';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/gesstwo/GE SS Two Light.otf') format('opentype');
}

@font-face {
  font-family: 'GE SS Two';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/gesstwo/GE SS Two Medium.otf') format('opentype');
}

@font-face {
  font-family: 'GE SS Two';
  font-style: normal;
  font-weight: bold;
  src: url('assets/fonts/gesstwo/GE SS Two Light.otf') format('opentype');
}

/* Gotham */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/gotham/Gotham Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 100;
  src: url('assets/fonts/gotham/Gotham Thin Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/gotham/Gotham Extra Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 200;
  src: url('assets/fonts/gotham/Gotham Extra Light Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/gotham/Gotham Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/gotham/Gotham Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/gotham/Gotham Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/gotham/Gotham Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/gotham/Gotham Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/gotham/Gotham Bold Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/gotham/Gotham Black.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/gotham/Gotham Ultra.otf') format('opentype');
}
