.chars-counter {
  margin-top: 8px;
  color: $dark-gray-color;
  font-size: var(--font-size-xs);
  line-height: 16px;
  text-align: right;

  &--invalid {
    color: $accent-color;
  }
}
