/*
 * Generals
 */
$border-radius: 4px;

/*
 * Colors
 */
$primary-color: #af96d4;
$secondary-color: #71b790;
$accent-color: #f05587;
$accent-darker-color: #c22658;
$base-color: #454545;
$gray-color: #b1b3b3;
$dark-gray-color: #888b8d;
$medium-gray-color: #e2e2e2;
$light-gray-color: #f4f4f4;
$light-blue-color: #e2edf2;
$success-color: #71b790;
$error-color: #e50f23;
$alert-color: #edc242;
$info-color: #00acec;
$table-pointer-color: #ffe500;

/*
 * medxsync colors
 */
$medxsync-primary-color: #00a4de;
$medxsync-secondary-color: #85c800;
$medxsync-alert-color: #edc242;

/*
 * municipality colors
 */
$municipality-primary-color: #57733b;
$municipality-secondary-color: #e1ac5d;
$municipality-alert-color: darken($municipality-secondary-color, 20%);

/*
 * fta colors
 */
$fta-border-gray: #d6d5d5;
$fta-light-gray: #F2F4F6;
$fta-gray: #bcc6cf;
$fta-dark-gray: #464646;
$fta-secondary: #ab8240;
$fta-success: #224261;
$fta-brand-blue: #1e3a56;
$fta-footer: #0c2238;
$fta-red: #ed1c24;
$fta-green: #00914a;

$fta-primary-color: $fta-brand-blue;
$fta-secondary-color: $fta-secondary;
$fta-alert-color: darken($fta-secondary-color, 10%);
$fta-light-gray-color: $fta-light-gray;

/*
 * Typography
 */
$base-font-family: 'Roboto', Arial, Helvetica, sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5;

$serif-font-family: 'Lora', serif;

$header-color: $primary-color;
$header-font-family: 'Montserrat', Arial, Helvetica, sans-serif;
$header-font-sizes: 30px, 24px, 20px, 18px, 16px, 16px;

/*
 * medxsync typography
 */
$medxsync-header-color: $medxsync-primary-color;

/*
 * municipality typography
 */
$municipality-header-color: $municipality-primary-color;

/*
 * fta typography
 */
$fta-header-color: $fta-primary-color;
$fta-base-font-family: 'Gotham', Arial, Helvetica, sans-serif;


/*
 * Tables
 */
$cell-height: 40px;
$cell-background-odd: #fff;
$cell-background-even: $light-gray-color;
$cell-background-no-data: $light-gray-color;
$cell-color: $base-color;

$header-cell-height: $cell-height;
$header-cell-background: $primary-color;
$header-cell-color: #fff;
$header-cell-font-family: $header-font-family;

/*
 * medxsync tables
 */
$medxsync-header-cell-background: $medxsync-primary-color;

/*
 * municipality tables
 */
$municipality-header-cell-background: $municipality-primary-color;

/*
 * fta tables
 */
$fta-header-cell-background: $fta-primary-color;

/*
 * Buttons
 */
$btn-font-family: $header-font-family;

$btn-normal-font-size: 14px;
$btn-small-font-size: 14px;
$btn-smaller-font-size: 12px;

$btn-normal-min-height: 40px;
$btn-small-min-height: 32px;
$btn-smaller-min-height: 20px;

$btn-normal-padding: 13px 20px;
$btn-small-padding: 9px 20px;
$btn-smaller-padding: 4px 20px 3px;

$btn-default-bg-color: #fff;
$btn-default-color: $primary-color;

$btn-primary-bg-color: $primary-color;
$btn-primary-color: #fff;

$btn-secondary-bg-color: $secondary-color;
$btn-secondary-color: #fff;

$btn-accent-bg-color: $accent-color;
$btn-accent-color: #fff;

$btn-alert-bg-color: $error-color;
$btn-alert-color: #fff;

/*
 * medxsync buttons
 */
$medxsync-btn-default-color: $medxsync-primary-color;
$medxsync-btn-primary-bg-color: $medxsync-primary-color;
$medxsync-btn-secondary-bg-color: $medxsync-secondary-color;

/*
 * municipality buttons
 */
$municipality-btn-default-color: $municipality-primary-color;
$municipality-btn-primary-bg-color: $municipality-primary-color;
$municipality-btn-secondary-bg-color: $municipality-secondary-color;

/*
 * fta buttons
 */
$fta-btn-primary-bg-color: $fta-primary-color;
$fta-btn-default-color: $fta-primary-color;
$fta-btn-secondary-bg-color: $fta-secondary-color;


/*
 * Inputs
 */
$input-font-family: $base-font-family;
$input-font-size: 1rem;
$input-line-height: 20px;
$input-padding-y: 10px;
$input-padding-x: 12px;
$input-padding: $input-padding-y $input-padding-x;

$input-default-bg-color: #fff;
$input-default-border-color: #ccc;
$input-default-color: $base-color;

$input-placeholder-color: lighten($base-color, 26%);

$input-focus-border-color: $primary-color;
$input-focus-color: $primary-color;

$input-disabled-color: $gray-color;

/*
 * medxsync inputs
 */
$medxsync-input-focus-border-color: $medxsync-primary-color;
$medxsync-input-focus-color: $medxsync-primary-color;

/*
 * municipality inputs
 */
$municipality-input-focus-border-color: $municipality-primary-color;
$municipality-input-focus-color: $municipality-primary-color;

/*
 * fta inputs
 */
$fta-input-focus-border-color: $fta-primary-color;
$fta-input-focus-color: $fta-primary-color;


/*
 * Input addons
 */
$input-addon-font-family: $base-font-family;
$input-addon-font-size: 16px;
$input-addon-line-height: 20px;
$input-addon-padding: 9px;

$input-addon-border-color: #ccc;
$input-addon-bg-color: $light-gray-color;
$input-addon-color: $gray-color;

/*
 * Icons
 */
$icon-email: "\e93b";
$icon-file-xls: "\e93c";
$icon-file-xlsx: "\e93d";
$icon-file-xml: "\e93e";
$icon-file-zip: "\e93f";
$icon-file-play: "\e937";
$icon-file-landscape: "\e938";
$icon-upload-server: "\e939";
$icon-upload: "\e93a";
$icon-cancel: "\e936";
$icon-refresh: "\e935";
$icon-warning: "\e900";
$icon-arrow-down: "\e932";
$icon-arrow-up: "\e933";
$icon-sign: "\e931";
$icon-ask: "\e930";
$icon-share: "\e92e";
$icon-download: "\e92d";
$icon-arrow-left: "\e916";
$icon-arrow-right: "\e917";
$icon-atom: "\e918";
$icon-dots: "\e919";
$icon-eye: "\e91a";
$icon-file-csv: "\e91b";
$icon-file-pdf: "\e91c";
$icon-print: "\e91e";
$icon-filter: "\e91f";
$icon-hamburger: "\e920";
$icon-person: "\e921";
$icon-point: "\e922";
$icon-star: "\e923";
$icon-drag: "\e912";
$icon-arrow-long: "\e913";
$icon-success: "\e911";
$icon-info: "\e90f";
$icon-error: "\e910";
$icon-disable: "\e924";
$icon-certificate: "\e925";
$icon-nutrient: "\e926";
$icon-metric: "\e90c";
$icon-multiselect: "\e908";
$icon-file: "\e90b";
$icon-video: "\e904";
$icon-image: "\e905";
$icon-radio: "\e909";
$icon-wheat: "\e90e";
$icon-checkbox: "\e90a";
$icon-calendar: "\e903";
$icon-regex: "\e90d";
$icon-typo: "\e906";
$icon-number: "\e907";
$icon-pencil: "\e927";
$icon-locked: "\e915";
$icon-remove: "\e914";
$icon-settings: "\e928";
$icon-table: "\e929";
$icon-plus-big: "\e92a";
$icon-plus: "\e92b";
$icon-cross-big: "\e92c";
$icon-cross: "\e902";
$icon-chevron: "\e901";
$icon-arrow: "\e934";
$icon-rollback: "\21B6";
$icon-search: "\e940";

$thumbnail_size: "150px";
