.display-attribute {
  word-break: break-word;

  &__header {
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
    font-size: var(--base-font-size);
    font-weight: var(--display-attribute-font-weight);
    line-height: 20px;

    > * {
      margin: {
        right: 5px;
        left: 0 !important;
      }
    }

    &--short-line {
      display: inline-block;
      min-width: 30%;
      line-height: 1;
    }
  }

  &__name {
    vertical-align: middle;
  }

  &__subname {
    display: block;
    font-weight: 400;
    font-size: var(--font-size-sm);
  }

  &__content {
    padding: 8px 10px;
    line-height: 20px;

    &--no-padding {
      padding: 0;
    }

    &--no-side-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__text {
    &--rtl {
      display: block;
      text-align: right;
      direction: rtl;
      unicode-bidi: bidi-override;
    }
  }

  &__comparison {
    display: flex;
    margin: 5px -20px 0;
  }

  &__column {
    flex: 0 1 50%;
    padding: 0 20px;
    border-right: 1px solid $gray-color;

    &:last-of-type {
      border-right: none;
    }
  }

  &__state-text {
    font-weight: var(--font-weight-bold);
  }

  &__value {
    margin-top: 10px;
  }
}
