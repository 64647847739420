.input-addon {
  z-index: 2;
  padding: $input-addon-padding;
  border: 1px solid $input-addon-border-color;
  border-radius: $border-radius;
  background-color: $input-addon-bg-color;
  color: $input-addon-color;
  font-family: $input-addon-font-family;
  font-size: var(--base-font-size);
  line-height: $input-addon-line-height;

  &--trigger {
    cursor: pointer;
  }
}
