.wysiwyg {
  * + h1,
  * + h2,
  * + h3,
  * + h4,
  * + h5,
  * + h6,
  * + p,
  * + blockquote,
  * + ol,
  * + ul,
  * + dl,
  * + table,
  * + figure {
    margin-top: 16px;
  }

  &--rtl {
    text-align: right;
    direction: rtl;
    unicode-bidi: bidi-override;

    & * {
      unicode-bidi: bidi-override;
    }

    & ul li:before {
      display: inline-block;
      margin-right: 0 !important;
      margin-left: 1em;
      transform: scaleX(-1);
    }
  }

  p {
    line-height: $base-line-height;
  }

  a {
    color: var(--wysiwig-anchor-color);
    text-decoration: underline;

    &:hover {
      color: var(--wysiwig-anchor-hover-color);
    }
  }

  em,
  cite {
    font-family: $serif-font-family;
    font-weight: 500;
    font-style: italic;
  }

  b,
  strong {
    font-weight: var(--font-weight-bold);
  }

  ul {
    line-height: $base-line-height;

    li {
      font-weight: 500;
      list-style: none;

      &:before {
        @include font-setup();
        content: $icon-arrow;
        vertical-align: middle;
        margin-right: 1em;
        color: $accent-color;
        font-size: 8.5px;
      }
    }
  }

  ol {
    line-height: $base-line-height;
    counter-reset: ordered-list;

    li {
      font-weight: 500;
      list-style: none;
      counter-increment: ordered-list;

      &:before {
        content: counter(ordered-list) '. ';
        color: var(--list-item-color);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  li > ul,
  li > ol {
    margin-left: 1.5em;
  }

  sub,
  sup {
    font-size: var(--font-size-xxs);
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }
}
