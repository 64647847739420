@mixin container() {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

@mixin row() {
  display: flex;
  gap: 32px;
}

@mixin column($i) {
  width: $i * 10%;
}

/*
  Replaced 12 columns system to 10 columns
  that was the simpler idea to migrate from Gridle library to pure scss without destroy the created layout
*/
@mixin column-classes($prefix: "--") {
  @for $i from 1 through 10 {
    &#{$prefix}#{$i} {
      @include column($i);
    }
  }
}

@mixin cf() {
  //*zoom: 1; - meant for legacy browsers

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin placeholder() {
  opacity: 1;
  color: $input-placeholder-color;
}

@mixin skeleton($shadows, $unique-name, $time, $color: #f5f5f5) {
  $length: length($shadows);
  $last-element: nth($shadows, $length);
  $height: nth($last-element, 2) + nth($last-element, 4);
  $width: 600px; /* shine width */

  $image: (
    linear-gradient(
      110deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 39%,
      rgba(255, 255, 255, 0.7) 49%,
      rgba(255, 255, 255, 0.7) 51%,
      rgba(255, 255, 255, 0) 61%,
      rgba(255, 255, 255, 0) 100%
    ),
  );

  $size: (
    $width $height, /* highlight */
  );

  $position: (
		-$width 0, /* highlight */
  );

  $position-anim: (
		calc(100% + #{$width}) 0, /* highlight */
  );

  @each $item in $shadows {
    $x: nth($item, 1);
    $y: nth($item, 2);
    $w: nth($item, 3);
    $h: nth($item, 4);
    $isRadial: nth($item, 5);

    @if ($isRadial) {
      $image: append($image, unquote('radial-gradient( circle #{$w} at #{$x} #{$y + $w}, #{$color} 100%, transparent 0 )'), 'comma');
      $size: append($size, unquote('#{$width} #{$height}'), 'comma');
      $position: append($position, unquote('0 0'), 'comma');
      $position-anim: append($position-anim, unquote('0 0'), 'comma');
    } @else {
      $image: append($image, unquote('linear-gradient( #{$color} #{$h}, transparent 0 )'), 'comma');
      $size: append($size, unquote('#{$w} #{$h}'), 'comma');
      $position: append($position, unquote('#{$x} #{$y}'), 'comma');
      $position-anim: append($position-anim, unquote('#{$x} #{$y}'), 'comma');
    }
  }

  @keyframes shine-#{$unique-name} {
		to {
			background-position: $position-anim;
		}
	}

  min-height: $height + 25px /* 25px for margin from footer */;
  background-repeat: no-repeat;
  background-image: $image;
  background-size: $size;
  background-position: $position;
  animation: shine-#{$unique-name} $time infinite;
}

@mixin animation {
  $animation-number: 0 !default !global;
  $animation-number: $animation-number + 1 !global;
  $name: unquote("animation-#{$animation-number}");
  animation-name: $name;
  @at-root {
    @keyframes #{$name} {
      @content;
    }
  }
}

@function get-max($map, $index) {
  $list: ();
  @each $item in $map {
    $list: append($list, nth($item, $index), comma);
  }

  @return max($list...);
}

@function get-height-skeleton($elements) {
  $max-top: get-max($elements, 2);
  $max-height: get-max($elements, 4);

  @return $max-top + $max-height;
}
