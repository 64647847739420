.simple-list {
  $this: & !default;

  &__item {
    display: block;
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;

    &--no-border {
      border: none;
    }

    &--selectable {
      position: relative;

      &:hover {
        cursor: pointer;
        background: rgba($primary-color, 0.2);
        border-radius: 5px;
      }
    }
  }

  &__item-key,
  &__item-value {
    position: relative;
    display: inline-block;
    line-height: 20px;
  }

  &__item-key {
    width: 160px;
    font-weight: var(--simple-list-font-weight);
    text-transform: capitalize;

    &--no-bold {
      font-weight: 400;
      text-transform: none;
    }

    &--large {
      width: 360px;
    }
  }

  &__update-icon {
    color: $info-color;
    font-size: var(--base-font-size);

    &#{$this}__update-icon--image {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  &__change-request-icon {
    color: $alert-color;
    font-size: var(--base-font-size);
  }

  &__images {
    &-container {
      display: flex;
      margin: 20px 0;
    }

    &-row {
      margin: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__checkbox {
    position: absolute !important;
    top: calc(50% - 12px);
    right: 0;
    padding-right: 0 !important;
  }

  &--table-view {
    width: 100%;
  }

  &--table-view &__item {
    display: flex;
    width: 100%;
    border-bottom: none;
    padding: 0;
  }

  &--table-view &__item-key,
  &--table-view &__item-value {
    width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
    vertical-align: middle;
  }

  &--table-view &__item-value &__item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--no-side-padding &__item {
    padding-left: 0;
    padding-right: 0;
  }
}
