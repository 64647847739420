.microcopy {
  color: $dark-gray-color;
  font-size: var(--font-size-xs);
  line-height: 16px;

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }
}
