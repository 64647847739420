.product-review {
  $this: & !default;

  &__count {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    display: flex;
    background-color: #e1e2e2;
    border-radius: 50%;
    color: #fff;
    font-size: var(--font-size-lg);
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    color: $primary-color;
    font-size: 20px;
  }

  &__steps {
    counter-reset: steps;
  }

  &__step {
    position: relative;
    padding-left: 100px;

    &:after {
      content: '';
      position: absolute;
      top: 68px;
      left: 34px;
      width: 2px;
      height: calc(100% - 68px + 20px);
      background-color: #cdcdcd;
      transform: translateX(-50%);
    }

    &--last {
      &:after {
        content: none;
      }
    }
  }

  &__step--approved {
    #{$this}__count,
    &:after {
      background-color: #39b88f;
    }
  }

  &__step--pending {
    #{$this}__count,
    &:after {
      background-color: #ffb220;
    }
  }

  &__step--rejected {
    #{$this}__count,
    &:after {
      background-color: #fe6998;
    }
  }

  &__step--minified {
    padding-left: 0;

    #{$this}__count,
    &:after {
      display: none;
    }
  }

  &__step + &__step {
    margin-top: 18px;
  }

  &__title {
    margin: 20px 0;
  }
}
