@mixin font-setup() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before, [class*=' icon-']:before {
  @include font-setup();
}

.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-file-xls {
  &:before {
    content: $icon-file-xls;
  }
}
.icon-file-xlsx {
  &:before {
    content: $icon-file-xlsx;
  }
}
.icon-file-xml {
  &:before {
    content: $icon-file-xml;
  }
}
.icon-file-zip {
  &:before {
    content: $icon-file-zip;
  }
}
.icon-file-play {
  &:before {
    content: $icon-file-play;
  }
}
.icon-file-landscape {
  &:before {
    content: $icon-file-landscape;
  }
}
.icon-upload-server {
  &:before {
    content: $icon-upload-server;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-sign {
  &:before {
    content: $icon-sign;
  }
}
.icon-ask {
  &:before {
    content: $icon-ask;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-atom {
  &:before {
    content: $icon-atom;
  }
}
.icon-dots {
  &:before {
    content: $icon-dots;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-file-csv {
  &:before {
    content: $icon-file-csv;
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-point {
  &:before {
    content: $icon-point;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
  }
}
.icon-arrow-long {
  &:before {
    content: $icon-arrow-long;
  }
}
.icon-success {
  &:before {
    content: $icon-success;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-disable {
  &:before {
    content: $icon-disable;
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}
.icon-nutrient {
  &:before {
    content: $icon-nutrient;
  }
}
.icon-metric {
  &:before {
    content: $icon-metric;
  }
}
.icon-multiselect {
  &:before {
    content: $icon-multiselect;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-radio {
  &:before {
    content: $icon-radio;
  }
}
.icon-wheat {
  &:before {
    content: $icon-wheat;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-regex {
  &:before {
    content: $icon-regex;
  }
}
.icon-typo {
  &:before {
    content: $icon-typo;
  }
}
.icon-number {
  &:before {
    content: $icon-number;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-locked {
  &:before {
    content: $icon-locked;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-table {
  &:before {
    content: $icon-table;
  }
}
.icon-plus-big {
  &:before {
    content: $icon-plus-big;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-cross-big {
  &:before {
    content: $icon-cross-big;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-chevron {
  &:before {
    content: $icon-chevron;
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}
.icon-rollback {
  &:before {
    content: $icon-rollback;
  }
}
