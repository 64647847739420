.workflow {
  display: block; // needed to animate component host
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px;
    margin: -13px 0 0 -13px;
    border-radius: 100%;
    border-top: 2px solid rgba(160, 160, 160, 0.15);
    border-right: 2px solid rgba(160, 160, 160, 0.15);
    border-bottom: 2px solid rgba(160, 160, 160, 0.15);
    border-left: 2px solid #6b6b6b;
    animation: loader .6s linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__content {
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 28px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: $border-radius;
      background: #deeef3;
    }
  }

  &__level:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__add-level {
    margin-top: 32px;
  }

  &--loading {
    opacity: 0.3;
    pointer-events: none;
  }

  &--loading:before {
    opacity: 1;
  }
}

.level {
  $padding: 15px;

  display: flex;

  &__label {
    flex: 0 0 120px;
    padding: 25px 0 28px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
  }

  &__actions {
    flex: 0 0 150px;
    padding: 15px 0 15px 15px;
    text-align: right;
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    padding: $padding 0;
    background: $light-gray-color;
    border-radius: $border-radius;
  }

  &__company {
    flex: 0 0 240px;
    padding: 0 $padding;
  }

  &__users-container {
    flex: 1 1 auto;
    border-left: 1px solid $gray-color;

    & > .errors {
      margin: 0 15px 15px;
    }
  }

  &__users-wrapper {
    display: flex;
  }

  &__users {
    flex: 1 1 auto;
    margin-bottom: -15px;
  }

  &__user {
    display: flex;
    align-items: flex-start;
    float: left;
    width: 33.33333%;
    min-width: 280px;
    padding: 0 $padding;
    margin-bottom: $padding;
  }

  &__user-select {
    flex: 1 0 auto;
    max-width: calc(100% - 61px);
  }

  &__add-user {
    flex: 0 0 180px;
    padding: 0 $padding;
  }

  &__user-remove {
    margin: 0 0 0 15px;
    padding: 4px 14px;
    font-size: var(--font-size-lg);
  }

  &__ordering {
    position: relative;
    display: flex;
    width: 34px;
    flex-direction: column;
    padding-right: 10px;
  }

  &__order-buttons {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    height: 70px;
    transition: height 0.3s ease;

    &--one {
      height: 35px;
    }
  }

  &__order-button {
    display: block;
    background: none;
    border: none;
    color: $primary-color;
    font-size: var(--font-size-lg);
    cursor: pointer;
    outline: none;

    &:before {
      display: block;
      margin: 7px 0;
    }
  }

  &__order-text {
    display: none;
  }
}
