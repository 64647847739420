.filters {
  margin-bottom: 32px;

  // Modificators for products list
  &--products-list {
    textarea {
      height: 147px;
      resize: none;

      &.input--textarea--small {
        height: 40px;
        min-height: 40px;
        max-height: 147px;
        resize: vertical;
      }
    }
  }

  &--toggled {
    padding: 0 20px;
    background: $light-gray-color;
  }

  &--toggled & {
    &__header {
      display: flex;
      align-items: center;
      padding: 20px 0;
    }

    &__heading {
      flex-grow: 1;
      font-weight: var(--font-weight-bold);
      font-size: 21px;
    }

    &__toggle-button {
      flex-shrink: 0;
      width: 150px;

      &:before {
        transition: transform 0.3s ease-out;
      }
    }

    &__content-wrapper {
      overflow: hidden;

      &--visible {
        overflow: visible;
      }
    }

    &__content {
      position: relative;
      padding: 20px 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $gray-color;
      }

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }
}

.used-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 920px;
  font-size: var(--font-size-sm);
  font-weight: 400;

  &__used-text,
  &__item {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    margin: 3px 6px 3px 0;
    padding: 4px 0;
  }

  &__item {
    padding: 4px 8px 4px 4px;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.23);
    }
  }

  &__item-delete {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: $base-color;
    border: 1px solid $light-gray-color;
    border-radius: 50%;
    background: $light-gray-color;
    padding: 0 6px;
    text-align: center;
    line-height: 16px;
    margin-right: 7px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: darken($light-gray-color, 10%);
    }
  }
}
