.label {
  position: relative;
  display: block;
  color: $base-color;
  font-size: var(--font-size-sm);
  text-transform: capitalize;

  &__tooltip-indicator {
    float: right;
    color: var(--primary-color);
    cursor: pointer;
  }

  &__tooltip-indicator--inline {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }

  &--required:after {
    content: '*';
    position: relative;
    top: -1px;
    color: $accent-color;
    font-size: 20px;
    line-height: 14px;
  }

  &--header {
    padding: 9px 7px;
    background-color: $medium-gray-color;
    border-radius: $border-radius;
    font-size: var(--base-font-size);
    font-weight: var(--font-weight-bold);
  }

  &--no-capitalize {
    text-transform: none;
  }
}
