@import 'intl-tel-sprites';

// rgba is needed for the selected flag hover state to blend in with
// the border-highlighting some browsers give the input on focus
$hoverColor: rgba(0, 0, 0, 0.05) !default;
$greyText: #999 !default;
$greyBorder: #CCC !default;

$flagHeight: 15px !default;
$flagWidth: 20px !default;
$flagPadding: 12px !default;

// this border width is used for the popup and divider, but it is also
// assumed to be the border width of the input, which we do not control
$borderWidth: 1px !default;

$arrowHeight: 4px !default;
$arrowWidth: 6px !default;
$triangleBorder: 3px !default;
$arrowPadding: 6px !default;
$arrowColor: #555 !default;

$inputPadding: 6px !default;
$selectedFlagWidth: $flagWidth + (2 * $flagPadding) !default;
$selectedFlagArrowWidth: $flagWidth + $flagPadding + $arrowWidth + (2 * $arrowPadding) !default;
$selectedFlagDialCodeWidth: $selectedFlagWidth + $flagPadding !default;
$selectedFlagArrowDialCodeWidth: $selectedFlagArrowWidth + $flagPadding !default;

// image related variables
$flagsImagePath: '/assets/images/' !default;
$flagsImageName: 'flags' !default;
$flagsImageExtension: 'png' !default;

// enough space for them to click off to close
$mobilePopupMargin: 30px;

.intl-tel-input {
  position: relative;
  z-index: 3;
  display: block;

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }

  .v-hide {
    visibility: hidden;
  }

  input,
  input[type=text],
  input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: $selectedFlagWidth;
    margin-right: 0;
  }

  .flag-container {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 0;
    z-index: 3;
    padding: $borderWidth;
  }

  .selected-flag {
    z-index: 1;
    position: relative;
    width: $selectedFlagWidth;
    height: 100%;
    padding: 0 0 0 $flagPadding;
    background: #f4f4f4;
    border-right: 1px solid #ccc;
    outline: none;

    .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .iti-arrow {
      position: absolute;
      top: 50%;
      margin-top: calc(-1 * ($arrowHeight / 2));
      right: $arrowPadding;
      width: 0;
      height: 0;
      border-left: $triangleBorder solid transparent;
      border-right: $triangleBorder solid transparent;
      border-top: $arrowHeight solid $arrowColor;

      &.up {
        border-top: none;
        border-bottom: $arrowHeight solid $arrowColor;
      }
    }
  }

  .country-list {
    position: absolute;
    top: calc(100% + 8px);
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 (-$borderWidth);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: $border-radius;
    white-space: nowrap;
    height: 220px;
    overflow-y: scroll;

    &.dropup {
      bottom: calc(100% + 8px);
      margin-bottom: (-$borderWidth);
    }

    .flag-box {
      display: inline-block;
      width: $flagWidth;
    }

    @media (max-width: 500px) {
      white-space: normal;
    }

    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: $borderWidth solid $greyBorder;
    }

    .country {
      padding: 12px;

      .dial-code {
        color: $greyText;
      }
    }

    .country.highlight {
      background-color: var(--primary-color);
      color: #fff;

      .dial-code {
        color: #e1e2e2;
      }
    }

    .flag-box, .country-name, .dial-code {
      vertical-align: middle;
    }

    .flag-box, .country-name {
      margin-right: 6px;
    }
  }

  &.allow-dropdown, &.separate-dial-code {
    input, input[type=text], input[type=tel] {
      padding-right: $inputPadding;
      padding-left: $selectedFlagArrowWidth + $inputPadding;
      margin-left: 0;
    }

    .flag-container {
      right: auto;
      left: 1px;
    }

    .selected-flag {
      width: $selectedFlagArrowWidth;
    }
  }

  &.allow-dropdown {
    .flag-container:hover {
      cursor: pointer;

      .selected-flag {
        background-color: $hoverColor;
      }
    }

    input[disabled] + .flag-container:hover,
    input[readonly] + .flag-container:hover {
      cursor: default;

      .selected-flag {
        background-color: transparent;
      }
    }
  }

  &.separate-dial-code {
    .selected-flag {
      background-color: $hoverColor;
      display: table;
    }

    .selected-dial-code {
      display: table-cell;
      vertical-align: middle;
      padding-left: $flagWidth + $flagPadding;
    }

    $charLength: 8px;

    @for $i from 2 through 5 {
      &.iti-sdc-#{$i} {
        input,
        input[type=text],
        input[type=tel] {
          padding-left: $selectedFlagDialCodeWidth + $inputPadding + ($i * $charLength);
        }

        .selected-flag {
          width: $selectedFlagDialCodeWidth + ($i * $charLength);
        }
      }

      &.allow-dropdown.iti-sdc-#{$i} {
        input,
        input[type=text],
        input[type=tel] {
          padding-left: $selectedFlagArrowDialCodeWidth + $inputPadding + ($i * $charLength);
        }

        .selected-flag {
          width: $selectedFlagArrowDialCodeWidth + ($i * $charLength);
        }
      }
    }
  }

  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: $borderWidth;

    &:hover {
      cursor: pointer;
    }
  }
}

.iti-mobile .intl-tel-input {
  &.iti-container {
    top: $mobilePopupMargin;
    bottom: $mobilePopupMargin;
    left: $mobilePopupMargin;
    right: $mobilePopupMargin;
    position: fixed;
  }

  .country-list {
    max-height: 100%;
    width: 100%;

    .country {
      padding: 10px 10px;
      line-height: 1.5em;
    }
  }
}

.iti-flag {
  width: $flagWidth;
  height: $flagHeight;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url('#{$flagsImagePath}#{$flagsImageName}.#{$flagsImageExtension}');
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: $flagWidth 0;

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url('#{$flagsImagePath}#{$flagsImageName}@2x.#{$flagsImageExtension}');
  }
}

.iti-flag.np {
  background-color: transparent;
}
